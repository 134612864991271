import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { variant } from "styled-system";

const Label = styled("label")`
  position: absolute;
  top: 15px;
  font-family: ${({ theme }) => theme.fonts.text};
  font-size: ${({ theme }) => theme.typography.size.m}px;
  line-height: 1.5em;
  color: ${({ theme }) => theme.colors.grey[3]};
  transition-duration: 0.2s;
  transition-property: top, font-size;
  pointer-events: none;

  ${variant({
    prop: "size",
    variants: {
      small: {
        top: "0",
        fontSize: ({ typography }) => `${typography.size.s}px`,
        color: "#4c4a4a",
        lineHeight: "1",
        marginTop: ({ space }) => `${space.xs}px`,
        zIndex: "2",
      },
    },
  })}

  ${variant({
    prop: "layout",
    variants: {
      error: {
        color: ({ colors }) => colors.brand.primary,
      },
      disabled: {
        color: ({ colors }) => colors.grey[5],
      },
      relative: {
        position: "relative",
      },
    },
  })}

    ${variant({
    prop: "position",
    variants: {
      relative: {
        position: "relative",
      },
      absolute: {
        postion: "absolute",
      },
    },
  })}
`;

const InputLabel = ({
  text,
  children,
  size,
  layout,
  className,
  position,
  htmlFor,
  ariaLabel,
}) => (
  <Label
    className={className}
    size={size}
    layout={layout}
    position={position}
    htmlFor={htmlFor}
    aria-label={ariaLabel}
  >
    {text}
    {children}
  </Label>
);

InputLabel.defaultProps = {
  children: null,
  layout: null,
  size: null,
  text: null,
  position: null,
  className: "",
  htmlFor: "id-string-needed",
};

InputLabel.propTypes = {
  text: PropTypes.string,
  position: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  size: PropTypes.string,
  layout: PropTypes.oneOf(["error", "disabled", "normal", "relative"]),
  className: PropTypes.string,
  htmlFor: PropTypes.string,
};

export default InputLabel;
