import { z } from "zod";
import { filterErrorsByStep, getDefaultValues, getFirstStepWithField, getSchema, mergeDataWithDefaultValues, } from "@/pages/CheckoutPage/util";
import { submitOrder, } from "@/pages/CheckoutPage/hooks/api";
import dataLayer from "@/helper/dataLayer";
const stepSchema = z.coerce.number();
const getNextStepIndex = (step, config) => (step !== config.steps.length ? step + 1 : -1);
export const handleSubmit = (addError, basePath, currentConfig, currentStep, mutateAsync, navigate, options, setErrors, setSubmitIsLoading, state, step, type, validate) => {
    const handleSubmitOrderResponse = (data) => {
        if (data.success) {
            if (data.tracking) {
                dataLayer(data.tracking);
            }
            navigate(`${basePath}/checkout/danke`, {
                state: {
                    ...history.state,
                    checkoutType: type,
                    deliveryPrice: data.summary.deliveryPrice,
                    email: data.data.contactAddressEmail,
                    firstName: data.data.contactAddressFirstName,
                    lastName: data.data.contactAddressLastName,
                    orderNo: data.orderNo,
                    paymentType: data.data.paymentType,
                    summary: data.summary,
                    links: data.links,
                    webIdent: data.webIdent,
                },
                replace: true,
            });
            return Promise.resolve();
        }
        if (data.error) {
            return Promise.reject(new Error(data.code ?? data.error));
        }
        if (data.validationErrors &&
            Object.keys(data.validationErrors).length > 0) {
            // eslint-disable-next-line no-console
            console.error(data.validationErrors);
            const firstStepWithErrors = getFirstStepWithField(Object.keys(data.validationErrors), options.currentConfig, options, state);
            if (firstStepWithErrors.index && firstStepWithErrors.step) {
                // TODO: move to hook internals
                const stepErrors = filterErrorsByStep(firstStepWithErrors.step, data.validationErrors, options, state);
                setErrors({
                    formErrors: [],
                    fieldErrors: stepErrors
                        ? Object.entries(stepErrors).reduce((errors, [path, message]) => {
                            errors[path] = [
                                {
                                    message: message,
                                },
                            ];
                            return errors;
                        }, {})
                        : {},
                });
                navigate(`${basePath}/checkout/step/${firstStepWithErrors.index}/`, {
                    replace: true,
                    state: {
                        keepErrors: true,
                    },
                });
                return Promise.resolve();
            }
        }
        if (data.paypalOrder) {
            return Promise.resolve(data.paypalOrder);
        }
        return Promise.resolve();
    };
    return (paypalOrderId) => {
        if (currentConfig && currentStep) {
            return validate(mergeDataWithDefaultValues(state, getDefaultValues(currentStep, [{}, state])), getSchema(currentStep, options, state)).then(async (success) => {
                if (success) {
                    try {
                        setSubmitIsLoading(true);
                        const data = await mutateAsync(state);
                        if (data.validationErrors) {
                            Object.entries(data?.validationErrors ?? []).forEach(([path, message]) => {
                                addError(path, message);
                            });
                        }
                        if (!data.validationErrors && currentConfig && currentStep) {
                            const nextStep = getNextStepIndex(Number(step), currentConfig);
                            if (nextStep > 0) {
                                navigate(`${basePath}/checkout/step/${nextStep}`);
                                return;
                            }
                            else {
                                return submitOrder(paypalOrderId)
                                    .then(handleSubmitOrderResponse)
                                    .catch((error) => {
                                    return Promise.reject(error);
                                });
                            }
                        }
                    }
                    catch (e) {
                        return Promise.reject(e);
                    }
                    finally {
                        setSubmitIsLoading(false);
                    }
                }
            });
        }
        return Promise.reject("No checkout config or step config provided");
    };
};
