import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import PlusBubble from "@core/svgs/plus-bubble.svg";

import AccessoryDetailsModal from "components/organisms/AccessoryDetailsModal";
import {
  useOfferConfigurator,
  SET_ACTIVE_ACCESSORY_VARIANT,
} from "features/Offer";
import { accessoryPropTypes } from "sharedPropTypes";
import dataLayer from "helper/dataLayer";

import { VariantsColorDropdown } from "components/molecules";

const Section = styled("div")({
  paddingTop: ({ theme: { space } }) => `${space.xl}px`,
  display: "flex",
});

const Body = styled(Section)({
  alignItems: "center",
  paddingTop: 0,
});

const Headline = styled("div")({
  paddingTop: "0.1em", // Even 4px is to big, 0.1em is 1.6 px...
  marginLeft: ({ theme: { space } }) => `${space.xs}px`,
});

const Dropdown = styled("div")({
  flex: 3,
  marginLeft: ({ theme: { space } }) => `${space.m}px`,
});

const StyledAccessoryImage = styled("div")({
  flex: 1,
  height: ({ theme: { space } }) => `${space.huge}px`,
  marginTop: ({ theme: { space } }) => `${space.m}px`,
  textAlign: "center",
});

const SelectedAccessories = ({ accessory, ebootisId, index }) => {
  const { dispatch } = useOfferConfigurator();

  if (!accessory || !ebootisId) return null;

  return (
    <>
      <Section>
        <PlusBubble />
        <Headline>
          {accessory.manufacturer && `${accessory.manufacturer} `}
          {accessory.name && accessory.name}
          <br />
          <AccessoryDetailsModal accessory={accessory} />
        </Headline>
      </Section>
      <Body>
        <StyledAccessoryImage>
          <AccessoryDetailsModal accessory={accessory} ebootisId={ebootisId} />
        </StyledAccessoryImage>
        <Dropdown>
          <VariantsColorDropdown
            extendedVariants={accessory.extendedVariants}
            // TODO refactor naming
            activeVariantId={ebootisId}
            onChange={(val) => {
              dispatch({
                type: SET_ACTIVE_ACCESSORY_VARIANT,
                payload: {
                  activeAccessoryVariantId: val,
                  index,
                },
              });
              dataLayer({
                eventAction: "select",
                eventCategory: "Accessory",
                eventLabel: `Speicherkapazität:${val}`,
              });
            }}
          />
        </Dropdown>
      </Body>
    </>
  );
};

SelectedAccessories.propTypes = {
  accessory: PropTypes.objectOf(accessoryPropTypes),
};

SelectedAccessories.defaultProps = {
  accessory: null,
};

export default SelectedAccessories;
