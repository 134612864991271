import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Linklists, MetaNavigation } from "components/molecules";

const StyledFooter = styled.footer`
  margin-top: ${({ theme }) => theme.space.xxlLong}px;

  &.theme--black {
    background-color: black;
    margin-top: 0;
    padding-top: ${({ theme }) => theme.space.xxlLong}px;
  }

  &.theme--karneval {
    background-color: #ffd60b;
    margin-top: 0;
  }
`;

// we need a custom function here since we have to support IE11...
const getUrlVars = () => {
  const vars = {};
  window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, (_, key, value) => {
    vars[key] = value;
  });
  return vars;
};

const PageFooter = ({ className, reduced }) => {
  const openModal = getUrlVars().fm;
  return (
    <StyledFooter id="page-footer" className={className}>
      {!reduced && <Linklists />}
      <MetaNavigation openModal={openModal} />
    </StyledFooter>
  );
};

PageFooter.defaultProps = {
  className: "",
  reduced: false,
};

PageFooter.propTypes = {
  className: PropTypes.string,
  reduced: PropTypes.bool,
};

export default PageFooter;
