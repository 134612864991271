import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useReducer, } from "react";
import styled from "styled-components";
import { useViewport } from "@core/theme";
import { AccessoryDetailsModal, GiftCardDetailsModal, HardwareDetailsModal, TariffDetailsModal, } from "@/components/molecules";
import { Modal } from "@/components/atoms";
import { Headline } from "@mm/ui";
export var ACTIONS;
(function (ACTIONS) {
    ACTIONS["SET_MODAL"] = "SET_MODAL";
    ACTIONS["CLEAR_MODAL"] = "CLEAR_MODAL";
})(ACTIONS || (ACTIONS = {}));
export function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.SET_MODAL: {
            return {
                ...state,
                modal: action.payload,
            };
        }
        case ACTIONS.CLEAR_MODAL: {
            return {
                ...state,
                modal: undefined,
            };
        }
        default:
            throw new Error("Reducer action not set");
    }
}
const initialState = {
    modal: undefined,
};
const ctx = createContext({
    state: initialState,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    dispatch: () => { },
});
const ModalOverlay = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.4);
`;
/**
 * For a good intro into page scroll behaviour and the background
 * of this solution, see:
 * https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
 */
const fixBodyScroll = () => {
    document.body.style.overflow = "hidden";
};
const releaseBodyScroll = () => {
    document.body.style.overflow = "auto";
};
export const ModalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { is } = useViewport();
    useEffect(() => {
        if (is?.mobile) {
            if (state.modal) {
                fixBodyScroll();
            }
            else {
                releaseBodyScroll();
            }
        }
    }, [state.modal]);
    // TODO: match modal type instead?
    // TODO: refactor
    return (_jsxs(ctx.Provider, { value: { state, dispatch }, children: [children, state.modal && (_jsxs(ModalOverlay, { role: "dialog", onClick: (e) => {
                    e.stopPropagation();
                    if (e.target === e.currentTarget) {
                        dispatch({ type: ACTIONS.CLEAR_MODAL });
                    }
                }, children: [state.modal?.type === "tariffDetails" && (_jsx(TariffDetailsModal, { ...state.modal })), state.modal?.type === "hardwareDetails" && (_jsx(HardwareDetailsModal, { ...state.modal })), state.modal?.type === "accessoryDetails" && (_jsx(AccessoryDetailsModal, { ...state.modal })), state.modal?.type === "giftCardDetails" && (_jsx(GiftCardDetailsModal, { ...state.modal })), (state.modal?.type === "redemptionConditions" ||
                        state.modal?.type === "contractPartner" ||
                        state.modal?.type === "legalNote") && (_jsxs(Modal, { onClose: () => dispatch({
                            type: ACTIONS.CLEAR_MODAL,
                        }), children: [_jsx(Headline, { variant: "h2", children: state.modal.title }), state.modal.content] })), state.modal?.type === "generic" && (_jsx(Modal, { onClose: () => dispatch({ type: ACTIONS.CLEAR_MODAL }), children: state.modal.content }))] }))] }));
};
export const useModal = () => useContext(ctx);
