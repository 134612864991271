import { find, flatten } from "lodash";

/**
 * Util to find a variant based on color and storage.
 * If no variant is found, we return an empty stock indicating the variant is soldOut
 *
 * @param variants object
 * @param color string
 * @param storage string
 *
 */
export const findVariantByColorAndStorage = (variants, color, storage) =>
  (color || storage) &&
  (variants.find(
    ({
      variant: {
        storage: variantStorage,
        color: { hex },
      },
    }) => color === hex && storage === variantStorage
  ) || { stock: 0 });

/**
 * Util to find a variant based on the variantId,
 * if the variantId or variant are not correct anymore
 * the first variant element will be returned.
 *
 * @param variants object
 * @param variantId string
 *
 */
export const findVariantById = (variants, variantId) =>
  find(variants, {
    variant: { ebootisId: variantId },
  }) || variants?.[0];

/**
 * Returns an extendedVariant: the selected one
 * @param extendedVariants array
 *
 */
export const getSelectedExtendedVariant = (extendedVariants = []) => {
  // find selected variants. selected variant ist based on current pdp url
  const selectedVariant = extendedVariants.find(({ isSelected }) => isSelected);

  // check if variant is available
  if (selectedVariant?.stock && selectedVariant.stock > 0) {
    return selectedVariant;
  }
  // if variant is not available, find another one with the same configuration (storage)
  const variantWithSameHardware = extendedVariants.find(
    ({ stock, variant }) =>
      stock > 0 && variant?.storage === selectedVariant?.variant?.storage
  );

  // return variant or find one with a stock. if everything is sold out, return the first variant
  return (
    variantWithSameHardware ||
    extendedVariants.find(({ stock }) => stock > 0) ||
    selectedVariant ||
    extendedVariants[0]
  );
};

/**
 * Returns an extendedVariant: the one  with stock > 0,
 * or the one with stock > 0. If none, then the first one
 * @param extendedVariants array
 *
 */
export const getStockedAccessoryExtendedVariant = (extendedVariants = []) =>
  find(extendedVariants, ({ variant }) => variant.stock > 0) ||
  extendedVariants[0];

/**
 * Return the lowest stock of the objects within the array
 */
export const getLowestStock = (items) =>
  Math.min(
    ...items.map(
      ({ stock }) => (typeof stock !== "undefined" ? stock : 9999) // if there's no stock we set it to 9999 to not mess up calculations
    )
  );

/**
 * Return the lowest stock of the accessories
 */
export const getActiveAccessoryVariantStock = (
  accessories,
  activeAccessoryVariantIds
) => {
  // if none of them are set it means there are no accessories
  // so we simulate a highly available product
  if (!accessories || !activeAccessoryVariantIds) return 9999;
  const stocks = Object.entries(activeAccessoryVariantIds || []).map(
    ([index, { ebootisId }]) => {
      const accessory = accessories[parseInt(index, 10)];
      const activeExtendedVariant = findVariantById(
        accessory.extendedVariants,
        ebootisId
      );
      if (!activeExtendedVariant) {
        return 0;
      }
      return getLowestStock([activeExtendedVariant, accessory]);
    }
  );

  return Math.min(...stocks);
};

/**
 * Get the maxium deliveryTime object
 * @param deliveryTimes array
 */
export const getMaxDeliveryTime = (deliveryTimes) =>
  deliveryTimes.reduce((result, deliveryTime) => {
    if (!result || deliveryTime.days > result.days) return deliveryTime;
    return result;
  }, null);

/**
 * Accessories (at rootInfo.accessories) have a different data structure - like this:
    {
        extendedVariants: ...
    }

    To make it compatible with getMaxDeliveryTime we extract all deliveryTimes from it.
 * @param accessories array - rootInfo.accessories
 */
export const getAccessoryDeliveryTimes = (accessories) => {
  if (!accessories) return [];
  return flatten(
    accessories.map(({ extendedVariants }) =>
      flatten(extendedVariants.map(({ variant }) => variant.deliveryTime))
    )
  );
};
