import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CheckoutSection, CheckoutSectionHeadline, CheckoutSectionText, RadioList, StyledInputText as InputText, StyledSelect, } from "../../components";
import { useCheckout } from "../../hooks/api";
import { ACTIONS as CONTEXT_ACTIONS, useCheckoutState, } from "../../context/CheckoutContext";
import styled from "styled-components";
import { useField, useValidation, ValidateOnEvent, } from "../../hooks";
import { ACTIONS, useModal } from "@/utils/context/ModalContext";
import { providers } from "../../providers";
const Row = styled.div `
  display: flex;
  width: 100%;
  gap: 1.5rem;
  @media (max-width: 760px) {
    flex-wrap: wrap;
  }
`;
const PrefixWrapper = styled.div `
  flex: 0 0 33%;
  @media (max-width: 760px) {
    flex: 1 0 100%;
  }
`;
const NumberWrapper = styled.div `
  flex: 1;
`;
const SvgButton = styled.button `
  position: relative;
  z-index: 1;
  text-decoration: none;
  border: none;
  background-color: transparent;
  font-size: 1rem;
  cursor: pointer;
  padding: 0;
  margin-left: 0.25rem;
`;
export const ProviderChangeSection = ({ defaultValues, schema, }) => {
    const { state, dispatch: dispatchContext } = useCheckoutState();
    const { dispatch } = useModal();
    const { errors: providerChangeErrors, fieldState: providerChange, setFieldState: setProviderChange, } = useField("providerChange", schema, Boolean(state?.providerChange || defaultValues?.providerChange) ?? true, {
        validateOn: ValidateOnEvent.Change,
        mutate: true,
    });
    const { errors: formerProviderErrors, fieldState: formerProvider, setFieldState: setFormerProvider, trigger: triggerFormerProvider, } = useField("formerProvider", schema, "");
    const { errors: numberPrefixErrors, fieldState: numberPrefix, setFieldState: setNumberPrefix, trigger: triggerNumberPrefix, } = useField("currentProviderNumberPrefix", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: numberErrors, fieldState: number, setFieldState: setNumber, trigger: triggerNumber, } = useField("currentProviderNumberExtension", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: numberPortingErrors, fieldState: numberPorting, setFieldState: setNumberPorting, } = useField("numberPorting", schema, Boolean(state?.numberPorting || defaultValues?.numberPorting) ?? false);
    const { isFetching, isLoading } = useCheckout();
    const { clearErrors, } = useValidation();
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: "Anbieterwechsel" }), _jsx(RadioList, { checked: providerChange ? "providerChange--yes" : "providerChange--no", id: "providerChange", items: [
                    {
                        key: "providerChange--yes",
                        label: "Ich wohne hier und möchte meinen bestehenden Anschluss zu o2 wechseln",
                    },
                    {
                        key: "providerChange--no",
                        label: "Ich benötige einen neuen Internet-Anschluss (z.B. beim Umzug)",
                    },
                ], onChange: (key) => {
                    clearErrors([
                        "formerProvider",
                        "currentProviderNumberPrefix",
                        "currentProviderNumberExtension",
                        "numberPorting",
                    ]);
                    if (key === "providerChange--no") {
                        dispatchContext({
                            type: CONTEXT_ACTIONS.RESET_FIELDS,
                            payload: [
                                "formerProvider",
                                "currentProviderNumberPrefix",
                                "currentProviderNumberExtension",
                                "numberPorting",
                            ],
                        });
                    }
                    setProviderChange(key === "providerChange--yes");
                }, disabled: isLoading || isFetching, error: providerChangeErrors?.[0]?.message }), providerChange && (_jsxs(_Fragment, { children: [_jsxs(CheckoutSectionText, { children: [_jsx("strong", { children: "Falls der Anschluss bei deinem jetzigen Anbieter noch nicht gek\u00FCndigt ist, k\u00FCmmert sich o2 um alle Formalit\u00E4ten und k\u00FCndigt den Anschluss f\u00FCr dich." }), _jsx(SvgButton, { type: "button", onClick: (e) => {
                                    e.stopPropagation();
                                    dispatch({
                                        type: ACTIONS.SET_MODAL,
                                        payload: {
                                            type: "generic",
                                            title: "Informationen zum Wechselservice",
                                            content: (_jsxs(_Fragment, { children: [_jsxs("p", { children: ["K\u00FCndige deinen Vertrag bitte nicht selbst - so sparst du dir viel Aufwand. Mit dem Wechselservice von o2 l\u00E4uft die K\u00FCndigung deines alten Vertrages wie von allein. Der \u00DCbergang vom alten zum neuen Anschluss funktioniert dann in der Regel unterbrechungsfrei.", _jsx("br", {}), "Der Vorteil: Du musst dann am Anschalttag nicht einmal zu Hause sein."] }), _jsx("p", { children: "F\u00FCr den Fall, dass du bereits gek\u00FCndigt hast, wird o2 dies trotzdem noch parallel f\u00FCr dich \u00FCbernehmen, damit die \u00DCbernahme reibungslos funktioniert." })] })),
                                        },
                                    });
                                }, "aria-haspopup": "dialog", "aria-label": "Informationen zum Wechselservice", children: _jsx("i", { className: "icon-MM_iconfont_Info", "aria-hidden": true }) })] }), _jsx(StyledSelect, { id: "formerProvider", label: "Bitte w\u00E4hle deinen Anbieter aus*", options: state.serviceProvider
                            ? [
                                {
                                    code: "CARRIER_NOT_FOUND",
                                    name: "Anbieter nicht aufgeführt",
                                },
                                ...providers[state.serviceProvider],
                            ]
                            : [], idField: "code", labelField: "name", onChange: (_, value) => {
                            setFormerProvider(value?.code ?? "");
                        }, onBlur: () => {
                            triggerFormerProvider();
                        }, value: formerProvider, disabled: isLoading || isFetching, message: formerProviderErrors?.[0]?.message, layout: formerProviderErrors && "error" }), _jsx(CheckoutSectionHeadline, { children: "Rufnummernmitnahme" }), _jsx(RadioList, { checked: numberPorting ? "numberPorting--yes" : "numberPorting--no", id: "numberPorting", items: [
                            {
                                key: "numberPorting--yes",
                                label: "Ja",
                            },
                            {
                                key: "numberPorting--no",
                                label: "Nein",
                            },
                        ], onChange: (key) => {
                            setNumberPorting(key === "numberPorting--yes");
                        }, disabled: isLoading || isFetching, error: numberPortingErrors?.[0]?.message }), numberPorting && (_jsx(CheckoutSectionText, { children: "Bitte gib hier die bisherige Rufnummer ein, die du behalten m\u00F6chtest." })), !numberPorting && (_jsxs(CheckoutSectionText, { children: [_jsx("strong", { children: "Bitte gib hier deine bisherige Rufnummer vom Anschluss ein." }), _jsx("br", {}), "Dies spart in vielen F\u00E4llen den Technikertermin. Bei mehreren Rufnummern muss nur eine angegeben werden. Es findet keine Rufnummernmitnahme statt."] })), _jsxs(Row, { children: [_jsx(PrefixWrapper, { children: _jsx(InputText, { id: "currentProviderNumberPrefix", label: "Vorwahl*", value: numberPrefix, message: numberPrefixErrors?.[0]?.message, layout: numberPrefixErrors && "error", onChange: (value) => {
                                        setNumberPrefix(value);
                                    }, onBlur: () => triggerNumberPrefix(), disabled: isLoading || isFetching, autoComplete: "none" }) }), _jsx(NumberWrapper, { children: _jsx(InputText, { id: "currentProviderNumberExtension", label: "Rufnummer*", value: number, message: numberErrors?.[0]?.message, layout: numberErrors && "error", onChange: (value) => {
                                        setNumber(value);
                                    }, onBlur: () => triggerNumber(), disabled: isLoading || isFetching, autoComplete: "none" }) })] })] }))] }));
};
