import { reduceArrayOfObjectsToObject } from "@/utils/reduceArrayOfObjectsToObject";
const getDefaultValuesForSections = (sections, configurationState) => {
    return sections.flatMap((section) => {
        if ("sections" in section) {
            return getDefaultValuesForSections(section.sections, configurationState);
        }
        if ("hide" in section && section.hide) {
            if (typeof section.hide === "function") {
                if (section.hide(...configurationState))
                    return [];
            }
            else {
                return [];
            }
        }
        if ("defaultValues" in section && section.defaultValues) {
            return [section.defaultValues];
        }
        return [];
    });
};
export const getDefaultValues = (config, configurationState) => {
    if ("sections" in config) {
        return getDefaultValuesForSections(config.sections, configurationState);
    }
    return config.steps.flatMap(({ sections }) => getDefaultValuesForSections(sections, configurationState));
};
export const getDefaultValuesForFields = (fields, config, configurationState) => {
    const defaultValues = reduceArrayOfObjectsToObject(getDefaultValues(config, configurationState));
    const keys = Array.isArray(fields) ? fields : [fields];
    return Object.keys(defaultValues)
        .filter((i) => keys.includes(i))
        .reduce((acc, key) => {
        acc[key] = defaultValues[key];
        return acc;
    }, {});
};
export const mergeDataWithDefaultValues = (data, defaultValues) => {
    const defaultValuesObject = reduceArrayOfObjectsToObject(defaultValues);
    return {
        ...data,
        ...Object.entries(defaultValuesObject).reduce((output, [key, value]) => {
            if (!(key in data) || data[key] === "") {
                output[key] = value;
            }
            // key is present in data and is not "", so we don't overwrite with default value
            return output;
        }, {}),
    };
};
