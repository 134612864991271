import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Image } from "@mm/ui";

import { giftcardPropTypes } from "sharedPropTypes";

import Price from "../Price";

const StyledGiftcardDisplay = styled.div`
  display: inline-block;
  position: relative;
  ${({ giftCardDisplayWidth }) =>
    giftCardDisplayWidth && `width: ${giftCardDisplayWidth}px;`}
  ${({ giftCardDisplayTop }) =>
    giftCardDisplayTop && `top: ${giftCardDisplayTop}px;`}
    ${({ giftCardDisplayWidthNonPx }) =>
    giftCardDisplayWidthNonPx && `width: ${giftCardDisplayWidthNonPx};`}
`;

const PriceContainer = styled.div`
  position: absolute;
  width: 100%;
  top: ${({ priceContainerTop }) => `${priceContainerTop}%;`};
  transform: translateY(-50%);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImg = styled(Image)`
  max-width: 100%;
  ${({ giftCardClampMaxWidth }) =>
    giftCardClampMaxWidth &&
    `
        img {
            max-width: 100%;
        }
    `};
`;

const StyledPrice = styled(Price)`
  width: auto;
  margin-right: 0;
`;

const GiftcardDisplay = (props) => {
  const {
    giftcard: { image, value },
    className,
    size,
    giftCardDisplayWidth,
    giftCardDisplayWidthNonPx,
    giftCardDisplayTop,
    giftCardClampMaxWidth,
    priceContainerTop,
  } = props;

  return (
    <StyledGiftcardDisplay
      className={className}
      giftCardDisplayWidth={giftCardDisplayWidth}
      giftCardDisplayWidthNonPx={giftCardDisplayWidthNonPx}
      giftCardDisplayTop={giftCardDisplayTop}
    >
      <StyledImg
        giftCardClampMaxWidth={giftCardClampMaxWidth}
        src={image}
        alt={""}
      />
      <PriceContainer priceContainerTop={priceContainerTop}>
        <StyledPrice size={size} price={value} />
      </PriceContainer>
    </StyledGiftcardDisplay>
  );
};

GiftcardDisplay.defaultProps = {
  className: "",
  size: "l",
  priceContainerTop: "50",
  giftCardDisplayWidth: "",
  giftCardDisplayWidthNonPx: "",
  giftCardDisplayTop: "50",
};

GiftcardDisplay.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  giftcard: giftcardPropTypes.isRequired,
  giftCardDisplayWidth: PropTypes.string,
  giftCardDisplayWidthNonPx: PropTypes.string,
  giftCardDisplayTop: PropTypes.string,
  priceContainerTop: PropTypes.string,
};

export default GiftcardDisplay;
