export var PaymentType;
(function (PaymentType) {
    PaymentType["Paypal"] = "paypal";
    PaymentType["Prepayment"] = "prepayment";
    PaymentType["NoPayment"] = "nopayment";
})(PaymentType || (PaymentType = {}));
export const getPaymentTypeLabel = (paymentType) => {
    if (paymentType === PaymentType.Paypal)
        return "Paypal";
    if (paymentType === PaymentType.Prepayment)
        return "Vorkasse";
    return undefined;
};
