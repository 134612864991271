import styled from "styled-components";
import { Button } from "@mm/ui";

export const AffiliateOfferContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  @media (min-width: ${({ theme }) => theme.breakpointsMap.lg.min}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const AffiliateOfferItem = styled.div`
  position: relative;
  margin: 20px 0;
`;

export const AffiliateButton = styled(Button)`
  width: 100%;
`;

export const StyledOfferHeadline = styled.h3`
  font-weight: 400;
  min-height: 48px;
  line-height: 1.5;
  margin: 16px 0;

  strong {
    font-weight: 600;
    text-transform: uppercase;
  }
`;
