import React from "react";
import PropTypes from "prop-types";
import WebID from "@core/svgs/webID.svg";
import DHL from "@core/svgs/dhl.svg";

import { List, Headline, Text, Surface, Row, Col } from "@mm/ui";

import GeneralModalShell from "../../atoms/GeneralModalShell";
import Price from "../../atoms/Price";

export const DeliveryInfoModal = ({ deliveryPrice, deliverySettings }) => {
  return (
    <GeneralModalShell
      headline="Versand und Identifikation"
      opener={<i className="icon-MM_iconfont_Info" aria-hidden />}
      ariaLabel="Details zu Versand und Identifikation"
      trackingObj={{
        event: "gaEvent",
        eventCategory: "Details",
        eventAction: "click",
        eventLabel: "Delivery and Identity",
      }}
    >
      <Surface variant="narrow">
        <Row mb={3}>
          <Col>
            <Headline variant="h2">Versand</Headline>
            <List>
              <li>
                Die Lieferung erfolgt innerhalb Deutschlands durch die DHL
                International GmbH.
              </li>
              <li>
                Die Lieferzeiten entnehmen Sie bitte den einzelnen
                Produktseiten.
              </li>
              <li>
                Die Lieferung erfolgt direkt nach Zahlungseingang und Freigabe
                Ihres Vertrages. Die dargestellten Lieferzeiten gelten somit ab
                Zahlungseingang und Freigabe des Vertrages.
              </li>
            </List>
            <Headline variant="h3" mt={5}>
              Identifikation der Personalien
            </Headline>
            <Text>
              Im Rahmen Ihres Einkaufs in unserem Webshop ist es notwendig, eine
              Identifikation Ihrer Personalien durchzuführen.{" "}
              {deliverySettings?.webid.enabled &&
                deliverySettings?.postident.enabled &&
                " Dazu können Sie zwischen zwei Verfahren wählen"}
            </Text>
          </Col>
        </Row>
        {deliverySettings?.webid.enabled && (
          <Row mb="6">
            <Col sm={5}>
              <div className="a-icon-border">
                <WebID />
              </div>
            </Col>
            <Col sm={19}>
              <Headline variant="h4">
                WebID — Online-Identifikation (empfohlen)
              </Headline>
              <Text highlighted display="block" mb={2}>
                {deliveryPrice.webId === 0
                  ? "Keine Versandkosten "
                  : [
                      "Versandkosten: ",
                      <Price
                        key="shipping-webid"
                        price={deliveryPrice.webId}
                        preventStyling
                      />,
                    ]}
              </Text>
              <Text display="block" mb={2}>
                WebID ist ein Online-Identifikationsverfahren, welches Ihnen
                ermöglicht, sich mit einem Endgerät mit Kamera und einem
                Ausweisdokument zu identifizieren.{" "}
                {deliveryPrice.webId === 0
                  ? "Dieses Verfahren verursacht keine weiteren Kosten für Sie. "
                  : [
                      "Dabei fallen im Normalfall Versandkosten in Höhe von ",
                      <Price
                        key="shipping-webid-copy-text"
                        price={deliveryPrice.webId}
                        preventStyling
                      />,
                      " an.",
                    ]}
                Außerdem müssen Sie sich nur einmalig identifizieren, da dies in
                Ihrem WebID Account gespeichert wird.
              </Text>
              <Text highlighted>
                Sie können sich während der folgenden Öffnungszeiten bei WebID
                online identifizieren: Montag bis Sonntag von 7-22Uhr
              </Text>
            </Col>
          </Row>
        )}
        {deliverySettings?.postident.enabled && (
          <Row mb="6">
            <Col sm={5}>
              <div className="a-icon-border">
                <DHL />
              </div>
            </Col>
            <Col sm={19}>
              <Headline variant="h4">
                DHL Ident-Verfahren — Überprüfung bei Auslieferung
              </Headline>
              <Text highlighted display="block" mb={2}>
                {deliveryPrice.postIdent === 0
                  ? "Aktion: Keine Versandkosten"
                  : [
                      "Versandkosten: ",
                      <Price
                        key="shipping-postIdent"
                        price={deliveryPrice.postIdent}
                        preventStyling
                      />,
                    ]}
              </Text>
              <Text display="block">
                {deliveryPrice.postIdent === 0
                  ? "Beim DHL Ident-Verfahren wird Ihre Identität bei der Zustellung überprüft. Dabei fallen keine Kosten an."
                  : [
                      "Beim DHL Ident-Verfahren wird Ihre Identität bei der Zustellung überprüft. Dabei fallen im Normalfall Versandkosten in Höhe von ",
                      <Price
                        key="shipping-postIdent-copy-text"
                        price={deliveryPrice.postIdent}
                        preventStyling
                      />,
                      " an.",
                    ]}
              </Text>
              <Text>
                Bitte halten Sie dazu bei Auslieferung eines der folgenden
                Ausweisdokumente parat:
              </Text>
              <List>
                <li>Deutscher Personalausweis</li>
                <li>Deutscher Reisepass</li>
                <li>Internationaler Ausweis oder Reisepass</li>
              </List>
              <Text>
                <br />
                Diese Ausweise werden auch als vorläufige Dokumente akzeptiert.
                Die Meldeadresse muss mit der Lieferadresse übereinstimmen. Die
                Sendung kann nur von Ihnen persönlich angenommen werden!
                Ehepartner oder auch bevollmächtigte Personen können Ihre
                Sendung nicht entgegennehmen!
              </Text>
            </Col>
          </Row>
        )}
        <Row mt={2}>
          <Col>
            <Headline variant="h3">Lieferung verfolgen</Headline>
            <Text>
              Mit der E-Mail zur Versandbestätigung Ihrer Bestellung erhalten
              Sie einen Link zur Sendungsverfolgung Ihres Paketes.
            </Text>
          </Col>
        </Row>
      </Surface>
    </GeneralModalShell>
  );
};

DeliveryInfoModal.propTypes = {
  deliveryPrice: PropTypes.shape({
    postIdent: PropTypes.number.isRequired,
    webId: PropTypes.number.isRequired,
  }).isRequired,
};

export default DeliveryInfoModal;
