import React from "react";
import styled from "styled-components";

import { GeneralModalShell, DetailsAccordion } from "components/atoms";
import { Headline } from "@mm/ui";
import dataLayer from "helper/dataLayer";

import TariffDetailModal from "../TariffDetailModal/TariffDetailModal";

const modalCouponContent = {
  descriptions: [
    {
      headline: "Einschränkungen",
      text: "Keine Barauszahlung. Ausgenommen sind Kunden, die durch einen im MediaMarkt, in der MediaMarkt Tarifewelt powered by S-KON, in einem Saturn Markt oder in der SATURN-Tarifewelt powered by SKON mit E.ON/eprimo abgeschlossenen Energieliefervertrag einen bereits bestehenden Energieliefervertrag mit E.ON/eprimo ersetzen und Kunden, die bereits mehr als einmal durch einen im MediaMarkt, in der MediaMarkt Tarifewelt powered by S-KON, in einem Saturn Markt oder in der der SATURN-Tarifewelt powered by S-KON abgeschlossenen Energieliefervertrag zwischen E.ON/eprimo gewechselt haben. Sollte der Energieliefervertrag aus einem, von Ihnen zu vertretendem Grund nicht zustande kommen, entfällt der Anspruch auf den Coupon. Haben Sie den Coupon bereits eingelöst, sind Sie verpflichtet, den Wert des eingelösten Coupon-Betrages an E.ON/eprimo zu erstatten. Nicht kombinierbar mit anderen Aktionen. Nicht einlösbar für: Verträge mit Drittanbietern, sämtliche Download-/Content-/GamingCards, Gutschein(-karten/-boxen), Prepaid-Aufladekarten/-Services, E-Books/Bücher, Zusatzgarantien, Lieferservice, Reparaturdienstleistungen, Fotoservice, Lebensmittel, IQOS, E-Zigaretten, Heets, Liquids und Tchibo Cafissimo Produkte.",
    },
    {
      headline: "Versand",
      text: "Nach Ablauf der Widerrufsfrist des abgeschlossenen Mobilfunk-, Strom/Gas- , oder DSL/Kabelvertrages wird Ihnen Ihr Geschenk-Coupon, gestückelt in Teilbeträge, an die bei der Bestellung angegebene E-Mail Adresse zugesendet. Bitte überprüfen Sie regelmäßig Ihr E-Mail Postfach und Ihren SPAM-Ordner.",
    },
  ],
};

const modalFoodNotesContentStromEon = {
  descriptions: [
    {
      headline: "1",
      text: "Ausgenommen von der Preisgarantie sind folgende Kostenbestandteile: Umsatzsteuer; neue Steuern, Abgaben oder sonstige unmittelbar die Beschaffung, Erzeugung, Speicherung, Netznutzung (Übertragung und Verteilung), Belieferung oder den Verbrauch von elektrischer Energie betreffenden Belastungen sowie entsprechende neuen Entlastungen.",
    },
    {
      headline: "2",
      text: "Der Strom wird in Höhe Ihres Verbrauchs aus regenerativen Energiequellen mit Standort in Deutschland gewonnen und in das Stromnetz eingespeist. Der Nachweis erfolgt über die Entwertung von Herkunftsnachweisen beim Umweltbundesamt.",
    },
  ],
};

const modalFoodNotesContentStromEonNatur = {
  descriptions: [
    {
      headline: "1",
      text: "Ausgenommen von der Preisgarantie sind folgende Kostenbestandteile: Umsatzsteuer; neue Steuern, Abgaben oder sonstige unmittelbar die Beschaffung, Erzeugung, Speicherung, Netznutzung (Übertragung und Verteilung), Belieferung oder den Verbrauch von elektrischer Energie betreffenden Belastungen sowie entsprechende neuen Entlastungen.",
    },
    {
      headline: "2",
      text: "Der Strom wird in Höhe Ihres Verbrauchs aus regenerativen Energiequellen mit Standort in Deutschland gewonnen und in das Stromnetz eingespeist. Der Nachweis erfolgt über die Entwertung von Herkunftsnachweisen beim Umweltbundesamt.",
    },
  ],
};

const modalFoodNotesContentGasEon = {
  descriptions: [
    {
      headline: "1",
      text: "Ausgenommen von der Preisgarantie sind Preisänderungen, die auf Änderungen der Umsatzsteuer beruhen sowie Preisänderungen, die auf nach Vertragsschluss während der Preisgarantie erstmals wirksam werdenden neuen Steuern, Abgaben oder sonstigen unmittelbar die Gewinnung, Produktion, Einspeisung, Beschaffung, Speicherung und Netznutzung (Transport über Fernleitungs- und Verteilnetze), Belieferung oder den Verbrauch von Erdgas betreffenden Belastungen oder entsprechenden neuen Entlastungen beruhen.",
    },
  ],
};

const modalFoodNotesContentGasEonNatur = {
  descriptions: [
    {
      headline: "1",
      text: "Ausgenommen von der Preisgarantie sind Preisänderungen, die auf Änderungen der Umsatzsteuer beruhen sowie Preisänderungen, die auf nach Vertragsschluss während der Preisgarantie erstmals wirksam werdenden neuen Steuern, Abgaben oder sonstigen unmittelbar die Gewinnung, Produktion, Einspeisung, Beschaffung, Speicherung und Netznutzung (Transport über Fernleitungs- und Verteilnetze), Belieferung oder den Verbrauch von Erdgas betreffenden Belastungen oder entsprechenden neuen Entlastungen beruhen.",
    },
  ],
};

const modalFoodNotesContentStromEprimo = {
  descriptions: [
    {
      headline: "1",
      text: "Während der vertraglich vereinbarten Laufzeit der eprimo-Preisgarantie ist eine Preisänderung ausgeschlossen. Von der Preisgarantie ausgenommen sind Änderungen der Umsatzsteuer und etwaige Preisänderungen, die die Stromsteuer und die künftig neue Steuern, Abgaben oder sonstige staatlich veranlasste, die Beschaffung, Erzeugung, Netznutzung (Übertragung und Verteilung) oder den Verbrauch von Strom betreffende Belastungen oder Entlastungen (vgl. Ziffer 6.4 der AGB) betreffen. Etwaige Preisänderungen erfolgen in dem in Ziffer 6.2 und / oder 6.4 der AGB beschriebenen Verfahren. Bei einer gesetzlichen Änderung der Umsatzsteuer gilt Ziffer 6.3 der AGB.",
    },
    {
      headline: "2",
      text: "Der Vertrag hat eine Vertragslaufzeit bis zum genannten Datum bzw. der genannten Monate und kann mit einer Frist von 1 Monat vor Ablauf der Laufzeit gekündigt werden (vgl. Ziffer 16.2 und 16.5 der AGB). Wenn keine Vertragslaufzeit vereinbart ist (vgl. Ziff. 15.1 der AGB) oder die ursprünglich vereinbarte Vertragslaufzeit ohne Kündigung abgelaufen ist (vgl. 15.2 der AGB), läuft der Vertrag auf unbestimmte Zeit. In diesen Fällen ist der Vertrag jederzeit mit einer Frist von einem Monat kündbar (vgl. Ziff. 16.1 der AGB).",
    },
  ],
};

const modalFoodNotesContentGasEprimo = {
  descriptions: [
    {
      headline: "1",
      text: "Für den Zeitraum vom 01.03.2023 bis zunächst 31.12.2023 profitieren Sie von der Gaspreisbremse der Bundesregierung. D. h. für diesen Zeitraum zahlen Sie auf 80 % Ihres im September 2022 prognostizierten Verbrauchs 12 ct/kWh brutto. Die Entlastung werden wir bei Ihren Abschlagszahlungen im o. g. Zeitraum berücksichtigen. Für die Monate Januar und Februar erhalten Sie eine rückwirkende Entlastung. Sind Sie bei uns Neukunde, dürfen wir die Entlastung erst gewähren, wenn uns Informationen dazu vorliegen, wie viel Sie beim Vorversorger bereits von dem entlastungsfähigen Verbrauch verbraucht haben („Entlastungskontingent“). Dies kann z. B. durch Vorlage der Abrechnung Ihres Vorversorgers erfolgen. Die Preisbremse greift nur, sofern Ihr vertraglicher Arbeitspreis über dem Arbeitspreis der Bremse liegt. Liegt er darunter, gelten die vertraglichen Konditionen. Besuchen Sie für weitere Informationen sowie eine Abschlagsberechnung unsere Internetseite unter https://www.eprimo.de/gaslage",
    },
    {
      headline: "2",
      text: "Der Vertrag hat eine Vertragslaufzeit bis zum genannten Datum bzw. der genannten Monate und kann mit einer Frist von 1 Monat vor Ablauf der Laufzeit gekündigt werden (vgl. Ziffer 16.2 und 16.5 der AGB). Wenn keine Vertragslaufzeit vereinbart ist (vgl. Ziff. 15.1 der AGB) oder die ursprünglich vereinbarte Vertragslaufzeit ohne Kündigung abgelaufen ist (vgl. 15.2 der AGB), läuft der Vertrag auf unbestimmte Zeit. In diesen Fällen ist der Vertrag jederzeit mit einer Frist von einem Monat kündbar (vgl. Ziff. 16.1 der AGB).",
    },
  ],
};

const DetailsLinkList = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpointsMap.sm.min}px) {
    align-items: flex-end;
    flex-direction: row;
  }
`;

const DetailsLinkBtn = styled("button")`
  text-decoration: underline;
  cursor: pointer;
  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
`;

const DetailsLink = styled("a")`
  font-size: 14px;
`;

const DetailsLinks = (props) => {
  const {
    tariff,
    tariff: { name, pibUrl, serviceProvider, apiData },
    coupon,
  } = props;

  const { energyType } = apiData;

  const eventLabel = (DetailLink) =>
    `Provider:${serviceProvider}:Tariff:${name}:DetailLink:${DetailLink}`;

  const isStrom = energyType === "1";
  const isStromNatur = name === "E.ON Strom Natur";
  const isGasNatur = name === "Erdgas Natur";

  return (
    <DetailsLinkList>
      <GeneralModalShell
        opener={<span className="energy">Tarifdetails</span>}
        trackingObj={{
          event: "gaEvent",
          eventCategory: "Details",
          eventAction: "click",
          eventLabel: eventLabel("Tarif-Details"),
        }}
      >
        <TariffDetailModal tariff={tariff} />
      </GeneralModalShell>
      {coupon && (
        <GeneralModalShell
          trackingObj={{
            event: "gaEvent",
            eventCategory: "Details",
            eventAction: "click",
            eventLabel: eventLabel("Coupon-Details"),
          }}
          opener={<span className="energy">Coupon-Details</span>}
        >
          <div>
            <Headline variant="h4">Details zum Geschenk-Coupon</Headline>
            <p>
              Die Höhe des Couponwerts ist von Ihrem jährlichen Verbrauch
              abhängig.
            </p>

            <p>
              eprimo-Strom und E.ON Strom Natur (12 M – Verfügbarkeit
              vorausgesetzt):
            </p>
            <ul>
              <li>
                Bei einem Verbrauch bis zu 3.499 kWh beträgt der Couponwert 50
                €.
              </li>
              <li>
                Der Couponwert erhöht sich bei einem Verbrauch von 3.500-4.999
                kWh auf 80 € und ab 5.000 kWh auf 120 €.
              </li>
            </ul>

            <p>E.ON Strom Stabil Natur (24 M):</p>
            <ul>
              <li>
                Bei einem Verbrauch bis zu 3.499 kWh beträgt der Couponwert 75
                €.
              </li>
              <li>
                Der Couponwert erhöht sich bei einem Verbrauch von 3.500-4.999
                kWh auf 100 € und ab 5.000 kWh auf 150 €.
              </li>
            </ul>

            <p>
              eprimo-Gas und E.ON Erdgas Neo (12 M – Verfügbarkeit
              vorausgesetzt):
            </p>
            <ul>
              <li>
                Bei einem Verbrauch bis zu 19.999 kWh beträgt der Couponwert 50
                €.
              </li>
              <li>
                Der Couponwert erhöht sich bei einem Verbrauch von 20.000-29.999
                kWh auf 100 € und ab einem Verbrauch von 30.000 kWh auf 150 €.
              </li>
            </ul>

            <p>E.ON Erdgas Stabil Neo (24 M):</p>
            <ul>
              <li>
                Bei einem Verbrauch bis 19.999 kWh beträgt der Couponwert 100 €.
              </li>
              <li>
                Der Couponwert erhöht sich bei einem Verbrauch von 20.000-29.999
                kWh auf 120 € und bei einem Verbrauch ab 30.000 kWh auf 180 €.
              </li>
            </ul>
          </div>
          <DetailsAccordion details={modalCouponContent} />
        </GeneralModalShell>
      )}
      {pibUrl && (
        <DetailsLink
          rel="noreferrer"
          href={pibUrl}
          target="_blank"
          onClick={() => {
            dataLayer({
              event: "gaEvent",
              eventCategory: "Details",
              eventAction: "click",
              eventLabel: eventLabel("Produktinformationsblatt"),
            });
          }}
          aria-label="Produktinformationsblatt öffnet in neuem Tab"
        >
          Produktinformationsblatt
        </DetailsLink>
      )}
      {serviceProvider === "eprimo" && (
        <GeneralModalShell
          trackingObj={{
            event: "gaEvent",
            eventCategory: "Details",
            eventAction: "click",
            eventLabel: eventLabel("Fußnoten"),
          }}
          opener={<span className="energy">Fußnoten 1) 2)</span>}
        >
          <DetailsAccordion
            details={
              isStrom
                ? modalFoodNotesContentStromEprimo
                : modalFoodNotesContentGasEprimo
            }
          />
        </GeneralModalShell>
      )}
      {serviceProvider === "E.ON" && (
        <GeneralModalShell
          trackingObj={{
            event: "gaEvent",
            eventCategory: "Details",
            eventAction: "click",
            eventLabel: eventLabel("Fußnoten"),
          }}
          opener={
            <span className="energy">
              {isStrom && isStromNatur
                ? "Fußnoten 1) 2)"
                : isStrom
                ? "Fußnoten 1) 2)"
                : ""}
              {!isStrom && isGasNatur
                ? "Fußnoten 1)"
                : !isStrom
                ? "Fußnoten 1)"
                : ""}
            </span>
          }
        >
          <DetailsAccordion
            details={
              isStrom
                ? isStromNatur
                  ? modalFoodNotesContentStromEonNatur
                  : modalFoodNotesContentStromEon
                : isGasNatur
                ? modalFoodNotesContentGasEonNatur
                : modalFoodNotesContentGasEon
            }
          />
        </GeneralModalShell>
      )}
    </DetailsLinkList>
  );
};

DetailsLinks.propTypes = {};

export default DetailsLinks;
