import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import Price from "@/components/atoms/Price";
import StarSvg from "@core/svgs/sternchen.svg";
import "./style.scss";
import { ACTIONS, useModal } from "@/utils/context/ModalContext";
export const TariffPriceDisplay = ({ className, tariff, }) => {
    const { dispatch } = useModal();
    return (_jsxs("section", { className: classNames("tariff-price-display", className), children: [_jsx("span", { className: "tariff-price-display__price-label", children: "Monatlicher Grundpreis" }), _jsxs("div", { className: "tariff-price-display__price-container", children: [_jsx(Price, { className: "tariff-price-display__price", size: "s", price: tariff.monthlyPrice, variant: "primary" }), tariff.legalNote && (_jsx("button", { className: "tariff-price-display__legalnote-button", onClick: (e) => {
                            e.preventDefault();
                            dispatch({
                                type: ACTIONS.SET_MODAL,
                                payload: {
                                    title: tariff.legalNote?.headline ?? "",
                                    type: "legalNote",
                                    content: tariff.legalNote?.text,
                                },
                            });
                        }, "aria-label": "Rechtliche Tarif-Details anzeigen", "aria-haspopup": "dialog", children: _jsx(StarSvg, { height: 10, width: 10, "aria-hidden": true }) }))] })] }));
};
