import React from "react";
import PropTypes from "prop-types";

import SinglePersonSvg from "@core/svgs/single-person.svg";
import CoupleSvg from "@core/svgs/couple.svg";
import GroupSvg from "@core/svgs/group.svg";
import GroupLargeSvg from "@core/svgs/group-large.svg";
import HouseSvg from "@core/svgs/house.svg";

import { GasIcon } from "./styledComponents";

const ConsumptionBtn = (props) => {
  const {
    energyType,
    minValue,
    maxValue,
    selectedValue,
    onClickHandler,
    position,
  } = props;

  const floorSize = [50, 100, 150, 200];
  const amountOfPeople = [
    <SinglePersonSvg key="SinglePersonSvg" />,
    <CoupleSvg key="CoupleSvg" />,
    <GroupSvg key="GroupSvg" />,
    <GroupLargeSvg key="GroupLargeSvg" />,
  ];

  return (
    <>
      <input
        type="radio"
        name="energy"
        className="msd-energy-btn__input hidden-input"
        id={`${position + 1}-person`}
        onClick={onClickHandler}
        onChange={onClickHandler}
      />
      <label
        onClick={onClickHandler}
        onChange={onClickHandler}
        className={
          selectedValue >= minValue && selectedValue <= maxValue
            ? "msd-energy-btn active"
            : "msd-energy-btn"
        }
        htmlFor={`${position + 1}-person`}
      >
        <span className="sr-only">{position + 1} Personen Haushalt</span>
        {energyType === "strom" ? (
          <>{amountOfPeople[position]}</>
        ) : (
          <GasIcon>
            <HouseSvg />
            <span className="gas-icon__text">{floorSize[position]}m²</span>
          </GasIcon>
        )}
      </label>
    </>
  );
};

ConsumptionBtn.defaultProps = {
  energyType: "strom",
  minValue: 0,
  maxValue: 9999,
  selectedValue: 1500,
  onClickHandler: () => null,
  position: 1,
};

ConsumptionBtn.propTypes = {
  energyType: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  selectedValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClickHandler: PropTypes.func,
  position: PropTypes.number,
};

export default ConsumptionBtn;
