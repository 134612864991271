import React from "react";
import { Surface } from "@mm/ui";
import { breakpoints } from "@core/theme/src/theme/theme";
import "./style.scss";

const ImageBanner = ({ data }) => {
  return (
    <>
      {data.linkUrl ? (
        <Surface variant="narrow">
          <a href={data.linkUrl} rel="noreferrer">
            <picture>
              <source
                srcSet={window.CDN_URL + data.images.xlUrl}
                media={`(min-width: ${breakpoints.xl})`}
              />
              <source
                srcSet={window.CDN_URL + data.images.lgUrl}
                media={`(min-width: ${breakpoints.lg})`}
              />
              <source
                srcSet={window.CDN_URL + data.images.mdUrl}
                media={`(min-width: ${breakpoints.md})`}
              />
              <source
                srcSet={window.CDN_URL + data.images.smUrl}
                media={`(min-width: ${breakpoints.sm})`}
              />
              <source
                srcSet={window.CDN_URL + data.images.xsUrl}
                media={`(min-width: ${breakpoints.xs})`}
              />
              <img
                className="msd-image-banner"
                alt={data.imageAltText}
                src={window.CDN_URL + data.images.lgUrl}
              />
            </picture>
          </a>
        </Surface>
      ) : (
        <Surface variant="narrow">
          <picture>
            <source
              srcSet={window.CDN_URL + data.images.xlUrl}
              media={`(min-width: ${breakpoints.xl})`}
            />
            <source
              srcSet={window.CDN_URL + data.images.lgUrl}
              media={`(min-width: ${breakpoints.lg})`}
            />
            <source
              srcSet={window.CDN_URL + data.images.mdUrl}
              media={`(min-width: ${breakpoints.md})`}
            />
            <source
              srcSet={window.CDN_URL + data.images.smUrl}
              media={`(min-width: ${breakpoints.sm})`}
            />
            <source
              srcSet={window.CDN_URL + data.images.xsUrl}
              media={`(min-width: ${breakpoints.xs})`}
            />
            <img
              className="msd-image-banner"
              alt={data.imageAltText}
              src={window.CDN_URL + data.images.lgUrl}
            />
          </picture>
        </Surface>
      )}
    </>
  );
};

export default ImageBanner;
