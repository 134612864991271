import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

const BorderBox = (props) => {
  const { children, className, noPadding, onClick, qaName } = props;
  return (
    <div
      data-codeception={qaName}
      className={
        noPadding
          ? `msd-border-box ${className}`
          : `msd-border-box padding-6 ${className}`
      }
      onClick={onClick}
    >
      {children}
    </div>
  );
};

BorderBox.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  noPadding: PropTypes.bool,
  qaName: PropTypes.string,
};

BorderBox.defaultProps = {
  className: "",
  noPadding: false,
  qaName: "",
};

export default BorderBox;
