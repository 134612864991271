import { BankAccountSection } from "./BankAccountSection";
import { ConnectionAddressSection } from "./ConnectionAddressSection";
import { ContactAddressSection } from "./ContactAddressSection";
import { ContactAndPaymentOverviewSection } from "./ContactAndPaymentOverviewSection";
import { DeliveryPaymentSection } from "./DeliveryPaymentSection";
import { ExistingCustomerToggleSection } from "./ExistingCustomerToggleSection";
import { IdentificationSection } from "./IdentificationSection";
import { PhoneSubscriberSection } from "./PhoneSubscriberSection";
import { PromotionCodeSection } from "./PromotionCodeSection";
import { ShippingAddressSection } from "./ShippingAddressSection";
import { LegalNoteSection } from "./LegalNoteSection";
import { WebIdentSection } from "./WebIdentSection";
import { o2SectionComponents } from "./o2";
import { einsUndEinsSectionComponents, } from "./1und1";
import { vodafoneSectionComponents, } from "./vodafone";
export * from "./BankAccountSection";
export * from "./ConnectionAddressSection";
export * from "./ContactAddressSection";
export * from "./ContactAndPaymentOverviewSection";
export * from "./DeliveryPaymentSection";
export * from "./ExistingCustomerToggleSection";
export * from "./IdentificationSection";
export * from "./PhoneSubscriberSection";
export * from "./PromotionCodeSection";
export * from "./ShippingAddressSection";
export * from "./LegalNoteSection";
export * from "./WebIdentSection";
export const sectionComponents = {
    ...einsUndEinsSectionComponents,
    ...o2SectionComponents,
    ...vodafoneSectionComponents,
    bankAccountSection: BankAccountSection,
    connectionAddressSection: ConnectionAddressSection,
    contactAddressSection: ContactAddressSection,
    contactAndPaymentOverviewSection: ContactAndPaymentOverviewSection,
    deliveryPaymentSection: DeliveryPaymentSection,
    existingCustomerToggleSection: ExistingCustomerToggleSection,
    identificationSection: IdentificationSection,
    phoneSubscriberSection: PhoneSubscriberSection,
    promotionCodeSection: PromotionCodeSection,
    shippingAddressSection: ShippingAddressSection,
    legalNoteSection: LegalNoteSection,
    webIdentSection: WebIdentSection,
};
