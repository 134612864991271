import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import { noop } from "lodash";

import { colorPropTypes } from "sharedPropTypes";
import ColorCircle from "components/molecules/ColorCircle";

export const Row = styled.div`
  display: flex;
  margin: 0.5em 0;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  align-items: center;
`;

const ColorName = styled.span`
  flex: 1;
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.grey[3] : theme.colors.grey[0]};
`;

const ColorRow = ({ color, onClick, colorSize, hideCount }) => {
  const disabled = color.count <= 0;
  return (
    <Row
      onClick={() => !disabled && onClick && onClick(color)}
      disabled={disabled}
    >
      <ColorCircle
        selected={color.selected}
        value={color.value}
        variant={colorSize}
        soldout={disabled}
        qaName={color.name}
        name={color.name}
        hexCode={color.value}
      />
      <ColorName disabled={disabled}>
        {`${color.name} ${
          (color.count && !hideCount && `(${color.count})`) || ""
        }`}
      </ColorName>
    </Row>
  );
};

ColorRow.propTypes = {
  color: colorPropTypes.isRequired,
  onClick: PropTypes.func,
  colorSize: PropTypes.oneOf(["small", "default"]),
  hideCount: PropTypes.bool,
};

ColorRow.defaultProps = {
  onClick: noop,
  colorSize: "default",
  hideCount: false,
};

export default ColorRow;
