import React from "react";
import PropTypes from "prop-types";
import { Text } from "@mm/ui";
import CheckmarkSvg from "@core/svgs/checkmark.svg";
import "./style.scss";

import { formatTariffText } from "utils/tariffUtils";

const Bulletlist = ({ list, variant, size, listLength }) => {
  if (!list) return null;

  const sliceLimit = listLength > 0 ? listLength : list.length;

  const itemClassNameByProp = `msd-bullet-list__item ${
    variant && `msd-bullet-list__item--${variant}`
  } ${size && `msd-bullet-list__item--size-${size}`}`;

  return (
    <ul className="msd-bullet-list">
      {list.slice(0, sliceLimit).map((item) => {
        const key = typeof item === "string" ? item : item.props.id;
        return (
          <li key={key} className={itemClassNameByProp}>
            {variant === "checkmark" && <CheckmarkSvg />}
            {/* local shop translations are not text but react components */}
            {typeof item === "string" ? (
              <Text
                dangerouslySetInnerHTML={{
                  __html: formatTariffText(item),
                }}
              />
            ) : (
              item
            )}
          </li>
        );
      })}
    </ul>
  );
};

Bulletlist.propTypes = {
  list: PropTypes.arrayOf(PropTypes.node).isRequired,
  listLength: PropTypes.number,
  variant: PropTypes.oneOf(["default", "checkmark"]),
  size: PropTypes.oneOf(["s", "m", "l"]),
};

Bulletlist.defaultProps = {
  listLength: 0,
  variant: "default",
  size: "l",
};

export default Bulletlist;
