import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
const Header = styled.p `
  color: ${(props) => props.theme.colors.grey[2]};
  font-size: 12px;
  line-height: 1.33;
  padding: 0;
  margin: 0 0 16px;
`;
const StyledLink = styled("a") `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  border-width: 2px;
  border-style: solid;
  font-family: NotoSansDisplay, Arial, sans-serif;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  text-align: center;
  padding: 0 24px;
  transition: background-color 0.2s ease;
  background-color: ${({ theme }) => theme.colors.grey[6]};
  border-color: ${({ theme }) => theme.colors.grey[6]};
  height: 48px;
  font-size: 16px;
  > :nth-child(n + 2) {
    margin-left: 4px;
  }
  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.white} !important;
    background-color: ${({ theme }) => theme.colors.grey[5]};
    border-color: ${({ theme }) => theme.colors.grey[5]};
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  }
`;
const NewsletterForm = ({ header }) => (_jsxs(_Fragment, { children: [header && _jsx(Header, { children: "Newsletter" }), _jsx(StyledLink, { href: "https://www.mediamarkt.de/de/myaccount/guest-newsletter", target: "_blank", rel: "noreferrer", "aria-label": "Newsletteranmeldung \u00F6ffnet in neuem Tab", "data-qa": "newsletter-link", children: "Zur Newsletter Anmeldung" })] }));
export default NewsletterForm;
