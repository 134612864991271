import React from "react";
import styled from "styled-components";
import { Flex, Dropdown } from "@mm/ui";
import PropTypes from "prop-types";

import { useViewport } from "@core/theme";

import { optionPropTypes } from "@mm/ui/src/sharedPropTypes";

import { colorPropTypes } from "sharedPropTypes";
import { WithVariantColors } from "hocs/WithVariantColors";
import ColorCircle from "components/molecules/ColorCircle";

const StyledColorCircle = styled(ColorCircle)`
  margin-right: ${({ theme }) => theme.space.s}px;
  cursor: ${({ soldout }) => (soldout ? "default" : "pointer")};
`;

/**
 Actual ColorPicker Rendering Component
 */
const ColorDots = ({
  colors,
  activeVariantId,
  internalActiveVariantId,
  onClick,
  setInternalActiveVariant,
  selected,
  className,
  options,
}) => {
  const viewport = useViewport();
  return viewport.greaterThan.sm ? (
    <>
      Farbe: <b data-qa="colorName">{selected && selected.name}</b>
      <Flex mt={2} className={className} data-codeception="colors">
        {colors.map(({ value, soldout, name, ...rest }, index) => (
          <StyledColorCircle
            key={rest.color}
            hexCode={rest.color}
            name={name}
            selected={value === (internalActiveVariantId || activeVariantId)}
            qaName={`Color-${index + 1}`}
            onClick={() => {
              // see TODO in LabeledDropdown
              if (!soldout) {
                onClick(value, rest);
              }
            }}
            onMouseEnter={() => {
              // see TODO in LabeledDropdown
              if (!soldout) {
                setInternalActiveVariant({
                  color: rest.color,
                });
              }
            }}
            onMouseLeave={() => {
              if (!soldout) {
                setInternalActiveVariant({});
              }
            }}
            soldout={soldout}
            sequentialNumer={index}
          />
        ))}
      </Flex>
    </>
  ) : (
    <Dropdown
      label="Farbe"
      className={className}
      options={options}
      onChange={onClick}
      selected={activeVariantId}
    />
  );
};

ColorDots.defaultProps = {
  className: "",
  internalActiveVariantId: null,
  selected: null,
};

ColorDots.propTypes = {
  accessories: PropTypes.array,
  activeAccessoryVariantIds: PropTypes.object,
  className: PropTypes.string,
  options: PropTypes.arrayOf(optionPropTypes).isRequired,
  selected: PropTypes.shape({
    name: PropTypes.string,
  }),
  activeVariantId: PropTypes.string.isRequired,
  setInternalActiveVariant: PropTypes.func.isRequired,
  internalActiveVariantId: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  colors: PropTypes.arrayOf(colorPropTypes).isRequired,
};

export default WithVariantColors(ColorDots);
