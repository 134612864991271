import PropTypes from "prop-types";
import React, { useState } from "react";
import { noop } from "lodash";

import Checkbox from "../../atoms/Checkbox";
import InlineLink from "../../atoms/InlineLink";

import "./style.scss";

const toggleSelection = (onChange, list, item, index, disabled) => {
  // clunky workaround for eslint/no-param-reassign
  if (disabled) return;
  const updated = list;
  updated[index].selected = !updated[index].selected;
  onChange(updated);
};

const CheckboxList = ({ list, onChange, limit = 0, suffix = "" }) => {
  const [expanded, setExpanded] = useState(false);
  // MMSD-2509: force a bool here, using `!!` to prevent `showMoreLink`
  // rendering the number "0" when it's supposed to be false
  const showMoreLink = !!(limit && list.length > limit);
  const visibleList =
    (showMoreLink && !expanded && list.slice(0, limit)) || list;

  return (
    <ul className="msd-checkbox-list">
      {visibleList.map((item, index) => {
        const disabled = item.count === 0;
        return (
          <li
            key={item.name}
            className="msd-checkbox-list__item"
            onChange={() =>
              toggleSelection(onChange, list, item, index, disabled)
            }
          >
            <Checkbox
              id={String(item.name).replace(" ", "")}
              disabled={disabled}
              checked={item.selected}
              qaName={item.name}
            />
            <label
              className={`msd-checkbox-list__label ${
                disabled ? `msd-checkbox-list__label--disabled` : ""
              }`}
              htmlFor={String(item.name).replace(" ", "")}
            >
              {`${item.name} ${suffix}`}
              {item.count > 0 && (
                <span aria-hidden="true">{`(${item.count})`}</span>
              )}
            </label>
          </li>
        );
      })}
      {showMoreLink && (
        <InlineLink onClick={() => setExpanded(!expanded)}>
          {`${(expanded && "Weniger anzeigen") || "Mehr anzeigen"} (${
            list.length - limit
          })`}
        </InlineLink>
      )}
    </ul>
  );
};

CheckboxList.propTypes = {
  // how many items should be visible (rest can be shown by expanding the list)
  limit: PropTypes.number,
  onChange: PropTypes.func,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
};

CheckboxList.defaultProps = {
  onChange: noop,
  limit: 10,
};

export default CheckboxList;
