import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

const Checkbox = ({ checked, className, disabled, onClick, id, qaName }) => {
  const classNameByProp = `msd-checkbox ${
    checked ? `msd-checkbox--checked` : ""
  } ${disabled ? `msd-checkbox--disabled` : ""} ${className}`;

  return (
    <input
      type="checkbox"
      className={classNameByProp}
      id={id}
      data-qa={qaName}
      disabled={disabled}
      checked={checked}
      onClick={onClick}
    />
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  qaName: PropTypes.string,
};

Checkbox.defaultProps = {
  checked: false,
  className: "",
  disabled: false,
  id: "",
};

export default Checkbox;
