import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { noop } from "lodash";

import { useViewport } from "@core/theme";

import { Box, Col, Row } from "@mm/ui";

import { useOfferConfigurator } from "features/Offer";

import { useFilterProvider } from "features/Filter/FilterProvider";

import {
  Bulletlist,
  TileBox,
  TileAnchor,
  OfferHeadline,
  Eyecatchers,
} from "components/atoms";

import {
  TarifCouponDisplay,
  TariffDetailsLinks,
  DeprecatedTariffPriceDisplay as TariffPriceDisplay,
} from "components/molecules";

import { tariffPropTypes } from "sharedPropTypes";

const TileWrapper = styled(TileBox)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledCol = styled(Col)`
  display: flex;
  margin-top: ${({ mt }) => mt};
  justify-content: ${({ isRowMode, viewport }) =>
    isRowMode && viewport.greaterThan.sm ? "center" : "start"};

  :not(:last-child) {
    margin-bottom: ${({ isRowMode, viewport }) =>
      (!isRowMode || viewport.lessThan.md) && "24px"};
  }

  align-items: center;
`;

const StyledImageCol = styled(StyledCol)`
  position: relative;
  justify-content: center;
  height: 180px;
`;

const TariffSection = styled.div`
  ul {
    margin-top: 8px;
    max-width: 218px;
  }
`;

const TariffOfferListItem = ({
  tariff,
  tariff: {
    bullets,
    carrier,
    name,
    eyeCatchers,
    pdpLink /* pibUrl, legalNotes */,
    idx,
  },
  mode,
  onClick,
  isActive,
  qaName,
}) => {
  const viewport = useViewport();

  const {
    state: { isListingPage, isProductDetailInterface, extendedVariant },
  } = useOfferConfigurator();

  const {
    state: { productType },
  } = useFilterProvider();

  const validateEyeCatcher = () => {
    if (!eyeCatchers) return null;
    return Object.keys(eyeCatchers)
      .map((item) => eyeCatchers[item].length >= 1)
      .find((item) => item === true);
  };

  const TileAnchorWrapper = ({ children, href }) => (
    <TileBox variant={mode}>
      <TileAnchor
        layout={mode}
        href={href}
        dataLayerObj={{
          eventCategory: "Produktteaser",
          eventAction: "click",
          eventLabel: `Details:${pdpLink}:${idx}:${productType}OfferTeaser`,
        }}
        qaName={qaName}
      >
        {children}
      </TileAnchor>
      <Box>
        <TariffDetailsLinks
          tariff={tariff}
          analytics={{ context: "TariffOfferListItem" }}
        />
        <TariffPriceDisplay tariff={tariff} size="s" />
      </Box>
    </TileBox>
  );

  const renderAnchorTag = isListingPage;

  const Wrapper = renderAnchorTag ? TileAnchorWrapper : TileWrapper;

  const isRowMode = mode === "row";

  return (
    <Wrapper
      onClick={() => onClick(tariff, extendedVariant)}
      hasShadow={isActive}
      href={pdpLink}
      qaName={qaName}
    >
      {validateEyeCatcher() && (
        <Eyecatchers eyecatchers={eyeCatchers} variant="offerItem" />
      )}
      <OfferHeadline prefix={carrier} text={name} lines={isRowMode ? 1 : 2} />
      <Row>
        <StyledImageCol md={isRowMode ? 12 : 24}>
          <TarifCouponDisplay
            tariff={tariff}
            isRowMode={isRowMode}
            layoutVariant={mode}
          />
        </StyledImageCol>
        <StyledCol
          isRowMode={!!isRowMode}
          viewport={viewport}
          md={isRowMode ? 12 : 24}
        >
          <TariffSection>
            <Bulletlist list={bullets} variant="checkmark" size="m" />
            {isProductDetailInterface && (
              <TariffDetailsLinks
                tariff={tariff}
                analytics={{
                  context: "TariffOfferListItem",
                }}
              />
            )}
          </TariffSection>
        </StyledCol>
      </Row>
      {isProductDetailInterface && (
        <Row mt="auto">
          <StyledCol
            mt="auto"
            isRowMode={!!isRowMode}
            viewport={viewport}
            md={isRowMode ? 8 : 24}
          >
            <TariffPriceDisplay tariff={tariff} size="s" />
          </StyledCol>
        </Row>
      )}
    </Wrapper>
  );
};

TariffOfferListItem.defaultProps = {
  mode: "tile",
  onClick: noop,
  isActive: false,
  qaName: undefined,
};

TariffOfferListItem.propTypes = {
  tariff: tariffPropTypes.isRequired,
  mode: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  qaName: PropTypes.string,
};

export default TariffOfferListItem;
