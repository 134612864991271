import React from "react";

import { Accordion, Table, Headline } from "@mm/ui";

import { detailsPropTypes } from "sharedPropTypes/";

const DetailsAccordion = ({
  details: { bullets, descriptions },
  autoWidth = false,
}) => (
  <>
    {descriptions?.map(({ headline, text }) => (
      <div
        key={`accessory-details-modal-desc-${headline}`}
        className="detailtext"
      >
        <Headline variant="h4">{headline}</Headline>
        <p>{text}</p>
      </div>
    ))}
    {bullets && (
      <Accordion
        entryList={bullets.reduce((acc, bulletlist) => {
          const { headline } = bulletlist;
          if (bulletlist.bullets.length > 0) {
            acc.push({
              header: headline,
              content: (
                <Table
                  variant={autoWidth ? "autoWidthDetails" : "details"}
                  rows={bulletlist.bullets.map(({ label, text }) =>
                    text !== ""
                      ? [
                          // some bullets dont have a label (just content) so
                          // we do not provide the first cell (td) in that case
                          // that way the table will display only the second
                          label && {
                            key: label,
                            content: label,
                          },
                          {
                            key: text,
                            content: (
                              <span
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                  __html: text,
                                }}
                              />
                            ),
                          },
                        ]
                      : null
                  )}
                />
              ),
            });
          }
          return acc;
        }, [])}
      />
    )}
  </>
);

DetailsAccordion.propTypes = {
  details: detailsPropTypes.isRequired,
};

export default DetailsAccordion;
