import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useReducer, useRef, useState, } from "react";
import { QueryClient } from "react-query";
import { Box, Button, Flex, Headline, InputText, Text } from "@mm/ui";
import { useViewport } from "@core/theme";
import CheckmarkSvg from "@core/svgs/checkmark.svg";
import ErrorSvg from "@core/svgs/icon-x.svg";
import { ACTIONS as INTERNET_PAGE_ACTIONS, useInternetProvider, useInternetCitiesPerZipCode, useInternetStreetsPerZipCodeAndCity, } from "@/features/Internet";
import { GeneralModalShell } from "@/components/atoms";
import { Countdown } from "@/components/organisms";
import dataLayer from "@/helper/dataLayer";
import { extendURLParams } from "@/utils/fetchData";
import { BackgroundSurface, ContentColor, ErrorSvgWrapper, FlexContainer, FlexWrapper, FormSurface, InnerWrapper, OptionList, StyledInputText, } from "./styledComponents";
var ACTIONS;
(function (ACTIONS) {
    ACTIONS["SET_ZIP"] = "SET_ZIP";
    ACTIONS["SET_CITY"] = "SET_CITY";
    ACTIONS["SET_CITY_OPTIONS"] = "SET_CITY_OPTIONS";
    ACTIONS["SET_STREET"] = "SET_STREET";
    ACTIONS["SET_STREET_OPTIONS"] = "SET_STREET_OPTIONS";
    ACTIONS["SET_STREET_NUMBER"] = "SET_STREET_NUMBER";
    ACTIONS["SET_PROMO_CODE"] = "SET_PROMO_CODE";
    ACTIONS["SET_MARKET_CODE"] = "SET_MARKET_CODE";
    ACTIONS["RESET_FORM"] = "RESET_FORM";
})(ACTIONS || (ACTIONS = {}));
const energyHeroConfig = {
    content: {
        flexDirection: ["column", "column", "row", "row", "row"],
        colSizeRight: ["100%", "100%", "50%", "50%", "50%"],
        colSizeLeft: ["100%", "100%", "50%", "50%", "50%"],
    },
    btnWrapper: {
        variant: ["wrap", "wrap", "wrap", "nowrap", "nowrap"],
        btnContainer: ["shrink", "shrink", "noShrink", "noShrink", "noShrink"],
    },
    inputWrapper: {
        variant: ["full", "full", "full", "half", "half"],
        inputText: ["full", "full", "full", "half", "half"],
        inputStreet: ["full", "full", "full", "big", "big"],
        inputNumber: ["full", "full", "full", "short", "short"],
    },
};
const resetPayload = {
    zip: {
        label: "PLZ",
        layout: "normal",
        value: "",
    },
    city: {
        label: "Stadt",
        layout: "normal",
        value: "",
        options: [],
        open: false,
        filteredOptions: [],
    },
    street: {
        label: "Straße",
        layout: "normal",
        value: "",
        options: [],
        open: false,
        filteredOptions: [],
    },
    number: {
        label: "Nummer",
        layout: "normal",
        value: "",
    },
    voucher: {
        label: "Aktionscode",
        layout: "normal",
        value: "",
        message: "",
    },
    marketCode: {
        label: "POS-ID",
        layout: "normal",
        value: "",
        message: "",
    },
};
const initialState = {
    zip: {
        label: "PLZ",
        layout: "normal",
        value: "",
    },
    city: {
        label: "Stadt",
        layout: "normal",
        value: "",
        options: [],
        open: false,
        filteredOptions: [],
    },
    street: {
        label: "Straße",
        layout: "normal",
        value: "",
        options: [],
        open: false,
        filteredOptions: [],
    },
    number: {
        label: "Nummer",
        layout: "normal",
        value: "",
    },
    voucher: {
        label: "Aktionscode",
        layout: "normal",
        value: "",
        message: "",
    },
    marketCode: {
        label: "POS-ID",
        layout: "normal",
        value: "",
        message: "",
    },
};
const InternetHero = () => {
    const { greaterThan: { sm }, } = useViewport();
    const [formAddress, setFormAddress] = useState({
        status: false,
    });
    const queryClient = new QueryClient();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleToggle = (isOpen) => {
        setIsModalOpen(isOpen);
    };
    /**
     * Next time when we have more data to share with the page call
     * we could build a sync action in the formReducer to set
     * the internal state in sync with page state
     */
    const formReducer = (state, action) => {
        switch (action.type) {
            case ACTIONS.SET_ZIP: {
                return {
                    ...state,
                    zip: handleNumberInput(state.zip, action.payload, "Bitte geben Sie Ihre PLZ ein."),
                };
            }
            case ACTIONS.SET_CITY: {
                return {
                    ...state,
                    city: handleSelectInput(state.city, action.payload.value, "Bitte geben Sie Ihre Stadt ein.", action.payload.open),
                };
            }
            case ACTIONS.SET_CITY_OPTIONS: {
                return {
                    ...state,
                    city: {
                        ...state.city,
                        options: action.payload,
                    },
                };
            }
            case ACTIONS.SET_STREET: {
                return {
                    ...state,
                    street: handleSelectInput(state.street, action.payload.value, "Bitte geben Sie Ihre Straße ein.", action.payload.open),
                };
            }
            case ACTIONS.SET_STREET_OPTIONS: {
                return {
                    ...state,
                    street: {
                        ...state.street,
                        options: action.payload,
                    },
                };
            }
            case ACTIONS.SET_STREET_NUMBER: {
                return {
                    ...state,
                    number: handleAddressNumberInput(state.number, action.payload, "Bitte gib eine Hausnummer ein."),
                };
            }
            case ACTIONS.SET_PROMO_CODE: {
                return {
                    ...state,
                    voucher: handleNumberInput(state.voucher, action.payload, "Bitte geben Sie einen Aktionscode ein."),
                };
            }
            case ACTIONS.SET_MARKET_CODE: {
                return {
                    ...state,
                    marketCode: {
                        ...action.payload,
                    },
                };
            }
            case ACTIONS.RESET_FORM: {
                return resetPayload;
            }
            default: {
                throw new Error("Reducer action not set");
            }
        }
    };
    const [state, dispatch] = useReducer(formReducer, initialState);
    const cityInputRef = useRef(null);
    const cityOptionsRef = useRef(null);
    const streetInputRef = useRef(null);
    const streetOptionsRef = useRef(null);
    const streetNumberInputRef = useRef(null);
    const { zip, city, street, number, voucher, marketCode } = state;
    const [previousZip, setPreviousZip] = useState(zip.value); //state for checking if zip code changed and thus form needs a reset
    const [marketId, setMarketId] = useState("");
    const { state: { address, availabilityChecked, offers, isLoading: isLoadingInternetProvider, page, }, dispatch: dispatchInternetProvider, } = useInternetProvider();
    useEffect(() => {
        if (availabilityChecked) {
            const element = document.getElementById("submitMessage");
            if (element) {
                const offset = 16;
                const targetScrollPosition = element.getBoundingClientRect().top + window.scrollY - offset;
                window.scrollTo({
                    top: targetScrollPosition,
                    behavior: "smooth",
                });
            }
        }
    }, [availabilityChecked]);
    useEffect(() => {
        if (page?.name !== "Internet") {
            dataLayer({
                event: undefined,
                dimension9: page?.name,
            });
        }
    }, [page]);
    const { hero } = page ?? {
        hero: {
            backgroundDesktop: "",
            fontColor: "black",
            headline: "",
            intro: "",
            modal01: "",
            modal01Intro: "",
            modal02: "",
            modal02Intro: "",
            promoCode: "",
            text: "",
            layout: "hero",
            countdown: {
                date: "",
                image: "",
                headline: "",
                headlinePostCountDown: "",
                subHeadline: "",
                subHeadlinePostCountDown: "",
            },
        },
    };
    const { backgroundDesktop = "https://via.placeholder.com/1440x900/DF0000", fontColor = "black", headline, intro, modal01, modal01Intro, modal02, modal02Intro, promoCode: displayPromoCode, text, layout, countdown, } = hero;
    const { url: pageUrl } = page ?? { page: undefined };
    const { data: citiesPerZipCode } = useInternetCitiesPerZipCode(zip.value, {
        enabled: zip.value.length === 5,
    });
    const { data: streets } = useInternetStreetsPerZipCodeAndCity(zip.value, city.value, {
        enabled: zip.value.length === 5 && city.value.length >= 2,
    });
    const findPromoCodeFromUrlParam = (url) => {
        const code = url.split("promo=")[1];
        if (!code) {
            return false;
        }
        return code;
    };
    function handleNumberInput(stateObject, value, message) {
        if (!/^[0-9]*$/.test(value)) {
            return {
                ...stateObject,
                message: "Es sind nur Zahlen erlaubt.",
                layout: "error",
            };
        }
        if (!value) {
            return {
                ...stateObject,
                value: "",
                message,
                layout: "error",
            };
        }
        return {
            ...stateObject,
            value,
            message: "",
            layout: "normal",
        };
    }
    function handleAddressNumberInput(stateObject, value, message) {
        if (!/^[1-9][0-9]*[a-zA-Z]?(\s*[/-]?\s*[1-9][0-9]*[a-zA-Z]?)?$/.test(value)) {
            return {
                ...stateObject,
                value,
                message: "Bitte gib eine gültige Hausnummer ein.",
                layout: "error",
            };
        }
        if (value.length > 10) {
            return {
                ...stateObject,
                value,
                message: "Deine Hausnummer darf maximal 10 Zeichen lang sein.",
                layout: "error",
            };
        }
        if (!value) {
            return {
                ...stateObject,
                value: "",
                message,
                layout: "error",
            };
        }
        return {
            ...stateObject,
            value,
            message: "",
            layout: "normal",
        };
    }
    function handleSelectInput(stateObject, value, message, open) {
        const regex = /[\w\säöüÄÖÜß\-.]+/g;
        if (!/^[a-z,A-Z,0-9,\-,., ,Ä-ä,Ü-ü,Ö-ö]*$/.test(value)) {
            return {
                ...stateObject,
                message: "Es sind nur Buchstaben erlaubt.",
                layout: "error",
                open,
            };
        }
        if (!value) {
            return {
                ...stateObject,
                value: "",
                layout: "normal",
                filteredOptions: stateObject.options,
                open,
            };
        }
        if (value.match(regex) &&
            !stateObject.options.find((a) => a.includes(value.match(regex)?.[0] ?? ""))) {
            return {
                ...stateObject,
                value,
                message: `Wir können diese ${stateObject.label} nicht finden`,
                layout: "error",
                filteredOptions: stateObject.options.filter((item) => {
                    return item.toLowerCase().includes(value.toLowerCase());
                }),
                open,
            };
        }
        return {
            ...stateObject,
            value,
            message: "",
            layout: "normal",
            filteredOptions: stateObject.options.filter((item) => {
                return item.toLowerCase().includes(value.toLowerCase());
            }),
            open,
        };
    }
    function validateMarketCode(value) {
        if (value.length >= 4) {
            const { tenant, environment, restUrl } = extendURLParams();
            fetch(`${restUrl}/v1/market/${value}?tenant=${tenant}&environment=${environment}`, {
                method: "get",
                credentials: "include",
            })
                .then((res) => {
                return res.json();
            })
                .then((data) => {
                const { code } = data;
                setTimeout(() => {
                    handleToggle(!isModalOpen);
                }, 1000);
                return dispatch({
                    type: ACTIONS.SET_MARKET_CODE,
                    payload: {
                        label: "marketCode",
                        value: code,
                        message: "Die POS-ID ist gültig und wurde gespeichert. Du kannst das Overlay schließen.",
                        layout: "success",
                    },
                });
            })
                .catch(() => {
                return dispatch({
                    type: ACTIONS.SET_MARKET_CODE,
                    payload: {
                        label: "marketCode",
                        value,
                        message: "Ungültige POS-ID.",
                        layout: "error",
                    },
                });
            });
        }
        else {
            return dispatch({
                type: ACTIONS.SET_MARKET_CODE,
                payload: {
                    label: "marketCode",
                    value,
                    message: "Bitte geben Sie eine POS-ID ein.",
                    layout: "normal",
                },
            });
        }
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const { tenant, environment, restUrl } = extendURLParams();
        if (zip.value && city.value && city.value && number.value) {
            setFormAddress({
                street: street.value,
                streetNumber: number.value,
                zip: zip.value,
                city: city.value,
                status: true,
                marketCode: marketCode?.value || "",
            });
        }
        dispatchInternetProvider({
            type: INTERNET_PAGE_ACTIONS.SET_IS_LOADING,
        });
        fetch(`${restUrl}/v2/internet/page/${pageUrl}?tenant=${tenant}&environment=${environment}`, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                street: street.value,
                streetNumber: number.value,
                zip: zip.value,
                city: city.value,
                status: true,
                marketCode: marketCode?.value || "",
            }),
        })
            .then((response) => {
            if (response.ok) {
                dispatchInternetProvider({
                    type: INTERNET_PAGE_ACTIONS.SET_IS_LOADING,
                    payload: false,
                });
            }
            return response.json();
        })
            .then((data) => {
            if (data.availabilityChecked) {
                if (offers.length >= 1) {
                    dataLayer({
                        event: "calcconfirm",
                        eventCategory: "Form",
                        eventAction: "submit",
                        eventLabel: "calculationconfirm",
                        producttype: "DSL",
                    });
                }
                else {
                    dataLayer({
                        event: "calculationerror",
                        eventCategory: "Form",
                        eventAction: "submit",
                        eventLabel: "calculationerror",
                        producttype: "DSL",
                    });
                }
            }
            dispatchInternetProvider({
                type: INTERNET_PAGE_ACTIONS.SYNC_CHECKED_DATA,
                payload: data,
            });
        });
    };
    useEffect(() => {
        if (citiesPerZipCode) {
            if (citiesPerZipCode.length === 1) {
                const city = citiesPerZipCode[0];
                dispatch({
                    type: ACTIONS.SET_CITY_OPTIONS,
                    payload: citiesPerZipCode,
                });
                dispatch({
                    type: ACTIONS.SET_CITY,
                    payload: { value: city, open: false },
                });
            }
            if (citiesPerZipCode.length > 1) {
                dispatch({
                    type: ACTIONS.SET_CITY_OPTIONS,
                    payload: citiesPerZipCode,
                });
                if (cityInputRef.current && !isLoadingInternetProvider) {
                    cityInputRef.current.focus();
                }
            }
        }
    }, [citiesPerZipCode, formAddress]);
    useEffect(() => {
        if (streets) {
            dispatch({
                type: ACTIONS.SET_STREET_OPTIONS,
                payload: streets,
            });
            if (streetInputRef.current) {
                streetInputRef.current.focus();
            }
        }
    }, [streets]);
    useEffect(() => {
        if (address?.marketCode) {
            dispatch({
                type: ACTIONS.SET_MARKET_CODE,
                payload: {
                    label: "marketCode",
                    value: address.marketCode,
                    message: "Die POS-ID ist gültig und wurde gespeichert. Du kannst das Overlay schließen.",
                    layout: "success",
                },
            });
            setMarketId(address.marketCode);
        }
        else {
            dispatch({
                type: ACTIONS.SET_MARKET_CODE,
                payload: {
                    label: "marketCode",
                    value: "",
                    message: "",
                    layout: "normal",
                },
            });
            setMarketId("");
        }
    }, [address]);
    useEffect(() => {
        const getTheUrlCode = findPromoCodeFromUrlParam(window.location.href);
        if (getTheUrlCode) {
            dispatch({
                type: ACTIONS.SET_PROMO_CODE,
                payload: getTheUrlCode,
            });
        }
    }, []);
    const navigateListItems = (event, item, type) => {
        const currentInput = event.target;
        currentInput?.classList.remove("active");
        if (event.key === "Enter") {
            dispatch({
                type: type,
                payload: {
                    value: item,
                    open: false,
                },
            });
        }
        if (event.key === "ArrowDown") {
            event.preventDefault();
            const nextInput = currentInput.nextElementSibling;
            if (nextInput) {
                nextInput.classList.add("active");
                nextInput.focus();
            }
        }
        if (event.key === "ArrowUp") {
            event.preventDefault();
            const previousInput = currentInput.previousElementSibling;
            if (previousInput !== null) {
                previousInput.classList.add("active");
                previousInput.focus();
            }
        }
    };
    return (_jsx(Box, { mb: 6, children: _jsxs("section", { className: "internet-hero", children: [_jsx(BackgroundSurface, { variant: "wide", shape: "sharp", color: "black", backgroundImg: backgroundDesktop ? backgroundDesktop : "", backgroundColor: "black", children: _jsx(_Fragment, { children: layout == "countdown" ? (_jsx(_Fragment, { children: countdown && (_jsx(Countdown, { layout: "internetHero", date: countdown.date, headline: countdown.headline, headlinePostCountDown: countdown.headlinePostCountDown, image: countdown.image, subHeadline: countdown.subHeadline, subHeadlinePostCountDown: countdown.subHeadlinePostCountDown })) })) : (_jsx(InnerWrapper, { children: _jsx(Flex, { flexDirection: energyHeroConfig.content.flexDirection, children: _jsx(Box, { width: energyHeroConfig.content.colSizeLeft, children: _jsxs(ContentColor, { variant: fontColor, children: [intro && _jsx(Text, { variant: "smallCopy", children: intro }), headline && _jsx(Headline, { variant: "h1", children: headline }), text && _jsx(Text, { children: text })] }) }) }) })) }) }), !availabilityChecked && (_jsx(FormSurface, { variant: "narrow", shape: "roundedSmall", children: _jsxs("form", { onSubmit: handleSubmit, className: "internet-hero__form", autoComplete: "off", children: [_jsx(Box, { mb: 6, children: _jsx("span", { className: "internet-hero__headline", children: "Pr\u00FCfe jetzt, welches Angebot an deiner Adresse verf\u00FCgbar ist" }) }), _jsxs(FlexWrapper, { justifyContent: "space-between", flexWrap: energyHeroConfig.btnWrapper.variant, children: [_jsxs(FlexContainer, { flexGrow: "2", variant: energyHeroConfig.inputWrapper.variant, children: [_jsx(StyledInputText, { variant: energyHeroConfig.inputWrapper.inputText, children: _jsx(InputText, { id: "zipcode", label: "PLZ", onChange: (payload) => {
                                                        dispatch({
                                                            type: ACTIONS.SET_ZIP,
                                                            payload,
                                                        });
                                                    }, onInput: (event) => {
                                                        const currentZip = event.target.value;
                                                        if ((zip.value.length > 4 &&
                                                            currentZip !== previousZip) ||
                                                            event.target.value === "") {
                                                            dispatchInternetProvider({
                                                                type: INTERNET_PAGE_ACTIONS.RESET_STATE,
                                                            });
                                                            dispatch({
                                                                type: ACTIONS.RESET_FORM,
                                                                payload: resetPayload,
                                                            });
                                                            setFormAddress({ status: false });
                                                            queryClient.invalidateQueries([
                                                                "useInternetOffersAvailabilityChecked",
                                                                "useInternetCitiesPerZipCode",
                                                            ]);
                                                        }
                                                        setPreviousZip(currentZip);
                                                    }, onFocus: () => {
                                                        dispatch({
                                                            type: ACTIONS.SET_CITY,
                                                            payload: {
                                                                ...city,
                                                                open: false,
                                                            },
                                                        });
                                                    }, on: true, value: zip.value, message: zip.message, layout: zip.layout, maxLength: "6", qaName: "zip" }) }), _jsxs(StyledInputText, { variant: energyHeroConfig.inputWrapper.inputText, children: [_jsx(InputText, { id: "city", label: "Ort", ref: cityInputRef, onKeyDown: (event) => {
                                                            if (event.key === "ArrowDown") {
                                                                event.preventDefault();
                                                                cityOptionsRef.current?.firstChild?.focus();
                                                            }
                                                            if (event.key === "ArrowUp") {
                                                                event.preventDefault();
                                                                cityOptionsRef.current?.lastChild?.focus();
                                                            }
                                                        }, onChange: (value) => {
                                                            dispatch({
                                                                type: ACTIONS.SET_CITY,
                                                                payload: {
                                                                    value,
                                                                    open: true,
                                                                },
                                                            });
                                                            dispatch({
                                                                type: ACTIONS.SET_STREET,
                                                                payload: {
                                                                    ...street,
                                                                    open: false,
                                                                },
                                                            });
                                                        }, onFocus: (value) => {
                                                            dispatch({
                                                                type: ACTIONS.SET_CITY,
                                                                payload: {
                                                                    value,
                                                                    open: true,
                                                                },
                                                            });
                                                            dispatch({
                                                                type: ACTIONS.SET_STREET,
                                                                payload: {
                                                                    ...street,
                                                                    open: false,
                                                                },
                                                            });
                                                        }, value: city.value, message: city.message, layout: city.layout, disabled: zip.value.length !== 5, qaName: "city" }), city.open && city.filteredOptions.length !== 0 && (_jsxs(OptionList, { tabIndex: -1, ref: cityOptionsRef, children: [city.value.length !== 0 &&
                                                                city.filteredOptions.map((item) => (_jsx("li", { tabIndex: 0, className: "option-list__item", onClick: () => {
                                                                        dispatch({
                                                                            type: ACTIONS.SET_CITY,
                                                                            payload: {
                                                                                value: item,
                                                                                open: false,
                                                                            },
                                                                        });
                                                                    }, onKeyDown: (event) => {
                                                                        navigateListItems(event, item, ACTIONS.SET_CITY);
                                                                    }, children: item }, item))), city.value.length === 0 &&
                                                                city.options.map((item) => (_jsx("li", { tabIndex: 0, className: "option-list__item", onClick: () => {
                                                                        dispatch({
                                                                            type: ACTIONS.SET_CITY,
                                                                            payload: {
                                                                                value: item,
                                                                                open: false,
                                                                            },
                                                                        });
                                                                    }, onKeyDown: (event) => {
                                                                        navigateListItems(event, item, ACTIONS.SET_CITY);
                                                                    }, children: item }, item)))] }))] }), _jsxs(StyledInputText, { variant: energyHeroConfig.inputWrapper.inputStreet, children: [_jsx(InputText, { id: "street", label: "Stra\u00DFe", ref: streetInputRef, onKeyDown: (event) => {
                                                            if (event.key === "ArrowDown") {
                                                                event.preventDefault();
                                                                streetOptionsRef.current?.firstChild?.focus();
                                                            }
                                                            if (event.key === "ArrowUp") {
                                                                event.preventDefault();
                                                                streetOptionsRef.current?.lastChild?.focus();
                                                            }
                                                        }, onChange: (value) => {
                                                            dispatch({
                                                                type: ACTIONS.SET_STREET,
                                                                payload: {
                                                                    value,
                                                                    open: true,
                                                                },
                                                            });
                                                        }, onFocus: () => {
                                                            dispatch({
                                                                type: ACTIONS.SET_STREET,
                                                                payload: {
                                                                    ...street,
                                                                    open: true,
                                                                },
                                                            });
                                                            dispatch({
                                                                type: ACTIONS.SET_CITY,
                                                                payload: {
                                                                    ...city,
                                                                    open: false,
                                                                },
                                                            });
                                                        }, value: street.value, message: street.message, layout: street.layout, disabled: !city.value ? true : false, qaName: "street" }), street.open && street.filteredOptions?.length > 0 && (_jsxs(OptionList, { tabIndex: -1, ref: streetOptionsRef, children: [street.value.length !== 0 &&
                                                                street.filteredOptions.map((item) => (_jsx("li", { tabIndex: 0, className: "option-list__item", onClick: () => {
                                                                        dispatch({
                                                                            type: ACTIONS.SET_STREET,
                                                                            payload: {
                                                                                value: item,
                                                                                open: false,
                                                                            },
                                                                        });
                                                                    }, onKeyDown: (event) => {
                                                                        navigateListItems(event, item, ACTIONS.SET_STREET);
                                                                    }, children: item }, item))), street.value.length === 0 &&
                                                                street.options.map((item) => (_jsx("li", { tabIndex: 0, className: "option-list__item", onClick: () => {
                                                                        dispatch({
                                                                            type: ACTIONS.SET_STREET,
                                                                            payload: {
                                                                                value: item,
                                                                                open: false,
                                                                            },
                                                                        });
                                                                    }, onKeyDown: (event) => {
                                                                        navigateListItems(event, item, ACTIONS.SET_STREET);
                                                                    }, children: item }, item)))] }))] }), _jsx(StyledInputText, { variant: energyHeroConfig.inputWrapper.inputNumber, children: _jsx(InputText, { id: "number", label: "Nummer", ref: streetNumberInputRef, onChange: (payload) => dispatch({
                                                        type: ACTIONS.SET_STREET_NUMBER,
                                                        payload,
                                                    }), onFocus: () => dispatch({
                                                        type: ACTIONS.SET_STREET,
                                                        payload: {
                                                            ...street,
                                                            open: false,
                                                        },
                                                    }), value: number.value, message: number.message, layout: number.layout, disabled: !street.value ? true : false, qaName: "streetnumber" }) })] }), sm && (_jsx(FlexContainer, { flexGrow: "1", flexBasis: "255px", children: _jsx(Button, { type: "submit", "data-qa": "calculate", 
                                            // disabled={fieldErrorStatus}
                                            disabled: number.value.length < 1, loading: isLoadingInternetProvider, children: "Verf\u00FCgbarkeit pr\u00FCfen" }) }))] }), _jsxs(Flex, { mt: 6, flexDirection: ["column", "column", "column", "row", "row"], alignItems: [
                                    "stretch",
                                    "stretch",
                                    "stretch",
                                    "flex-start",
                                    "flex-start",
                                ], children: [displayPromoCode && (_jsx(StyledInputText, { variant: ["full", "full", "full", "full", "short"], children: _jsx(InputText, { id: "promoCode", label: "Aktionscode", onChange: (payload) => dispatch({
                                                type: ACTIONS.SET_PROMO_CODE,
                                                payload,
                                            }), value: voucher.value, message: voucher.message, layout: voucher.layout, maxLength: "5" }) })), modal01Intro && (_jsxs(Flex, { mt: [6, 6, 6, "35px", "35px"], mr: [0, 0, 0, 8, 8], ml: [
                                            0,
                                            0,
                                            0,
                                            displayPromoCode ? 4 : 0,
                                            displayPromoCode ? 4 : 0,
                                        ], children: [_jsx("span", { className: "internet-hero__subheadline", children: modal01Intro }), _jsx(GeneralModalShell, { opener: _jsx("i", { className: "icon-MM_iconfont_Info", "aria-hidden": true }), ariaLabel: "Informationen bei Umzug anzeigen", trackingObj: {
                                                    event: "gaEvent",
                                                    eventCategory: "Details",
                                                    eventAction: "click",
                                                    eventLabel: "Du ziehst um?",
                                                }, children: _jsx("div", { dangerouslySetInnerHTML: { __html: modal01 } }) })] })), modal02Intro && (_jsxs(Flex, { mt: [6, 6, 6, "35px", "35px"], mr: [0, 0, 0, 8, 8], children: [_jsx("span", { className: "internet-hero__subheadline", children: modal02Intro }), _jsx(GeneralModalShell, { ariaLabel: "Weitere Details anzeigen", opener: _jsx("i", { className: "icon-MM_iconfont_Info", "aria-hidden": true }), trackingObj: {
                                                    event: "gaEvent",
                                                    eventCategory: "Details",
                                                    eventAction: "click",
                                                    eventLabel: "Weitere Details anzeigen",
                                                }, children: _jsx("div", { dangerouslySetInnerHTML: { __html: modal02 } }) })] })), _jsxs(Flex, { mt: [6, 6, 6, "35px", "35px"], children: [_jsx("button", { type: "reset", className: "changeAddressButton", onClick: () => {
                                                    dispatchInternetProvider({
                                                        type: INTERNET_PAGE_ACTIONS.RESET_STATE,
                                                    });
                                                    dispatch({
                                                        type: ACTIONS.RESET_FORM,
                                                        payload: resetPayload,
                                                    });
                                                    setFormAddress({ status: false });
                                                    queryClient.invalidateQueries([
                                                        "useInternetOffersAvailabilityChecked",
                                                        "useInternetCitiesPerZipCode",
                                                    ]);
                                                }, children: "Adresse l\u00F6schen" }), _jsxs(GeneralModalShell, { isOpen: isModalOpen, onToggle: handleToggle, opener: _jsxs("span", { className: "internet", children: ["POS-ID", marketCode.layout === "success" ? (_jsxs("span", { children: [": ", marketId] })) : ("")] }), children: [_jsx("p", { children: "Bitte gib deine POS-ID ein, damit wir passende Angebote anzeigen k\u00F6nnen." }), _jsxs(Flex, { justifyContent: "space-between", flexWrap: "wrap", children: [_jsx(StyledInputText, { variant: ["full", "full", "full", "full", "full"], children: _jsx(InputText, { id: "marketCode", label: "POS-ID", onChange: (payload) => setMarketId(payload), value: marketId, message: marketCode?.message, layout: marketCode?.layout, maxLength: "4" }) }), _jsx(Box, { ml: "6", children: _jsx(Button, { type: "button", onClick: () => validateMarketCode(marketId), children: "Validieren" }) })] })] })] })] }), !sm && (_jsx(FlexContainer, { flexGrow: "1", flexBasis: "200px", mt: "6", children: _jsx(Button, { type: "submit", color: "primary", "data-qa": "calculate", disabled: number.value.length < 1, loading: isLoadingInternetProvider, children: "Verf\u00FCgbarkeit pr\u00FCfen" }) }))] }) })), availabilityChecked && offers.length >= 1 && (_jsx(FormSurface, { variant: "narrow", shape: "roundedSmall", children: _jsx("div", { id: "submitMessage", className: "internet-hero__submitMessage", children: _jsxs(Flex, { alignItems: "center", children: [_jsx(Box, { minWidth: 60, children: _jsx(CheckmarkSvg, { width: 60, height: 60 }) }), _jsxs(Box, { ml: [2, 4, 6, 7, 8], children: [_jsx("strong", { children: "Pr\u00FCfung an deiner Wunschadresse erfolgreich abgeschlossen." }), _jsx("br", {}), _jsx("br", {}), address && (_jsxs("span", { children: [address?.zip, " ", address?.city, ", ", address?.street, " ", address?.streetNumber] })), _jsx("br", {}), _jsx("br", {}), _jsx("button", { type: "button", className: "changeAddressButton", onClick: () => {
                                                dispatchInternetProvider({
                                                    type: INTERNET_PAGE_ACTIONS.RESET_STATE,
                                                });
                                                dispatch({
                                                    type: ACTIONS.RESET_FORM,
                                                    payload: resetPayload,
                                                });
                                                setFormAddress({ status: false });
                                                queryClient.invalidateQueries([
                                                    "useInternetOffersAvailabilityChecked",
                                                    "useInternetCitiesPerZipCode",
                                                ]);
                                            }, children: "Adresse \u00E4ndern" }), marketCode.value && (_jsxs("button", { type: "button", className: "posIdButton", children: ["POS-ID", marketCode.layout === "success" ? (_jsxs("span", { children: [": ", marketId] })) : ("")] }))] })] }) }) })), availabilityChecked && offers.length === 0 && (_jsx(FormSurface, { variant: "narrow", shape: "roundedSmall", children: _jsx("div", { className: "internet-hero__submitMessage", children: _jsxs(Flex, { alignItems: "center", children: [_jsx(Box, { minWidth: 60, children: _jsx(ErrorSvgWrapper, { children: _jsx(ErrorSvg, {}) }) }), _jsxs(Box, { ml: [2, 4, 6, 7, 8], children: [_jsx("strong", { children: "Leider gibt es kein passendes Angebot an deiner Adresse" }), _jsx("br", {}), _jsx("br", {}), _jsxs("span", { children: [address?.zip, " ", address?.city, ", ", address?.street, " ", address?.streetNumber] }), _jsx("br", {}), _jsx("br", {}), _jsx("button", { type: "button", className: "changeAddressButton", onClick: () => {
                                                dispatchInternetProvider({
                                                    type: INTERNET_PAGE_ACTIONS.RESET_STATE,
                                                });
                                                dispatch({
                                                    type: ACTIONS.RESET_FORM,
                                                    payload: resetPayload,
                                                });
                                                setFormAddress({ status: false });
                                                queryClient.invalidateQueries([
                                                    "useInternetOffersAvailabilityChecked",
                                                    "useInternetCitiesPerZipCode",
                                                ]);
                                            }, children: "Adresse \u00E4ndern" })] })] }) }) }))] }) }));
};
export default InternetHero;
