import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { Image, Surface, Col, Row } from "@mm/ui";

import PlusBubble from "@core/svgs/plus-bubble.svg";

import BorderBox from "../../atoms/BorderBox";
import Bulletlist from "../../atoms/Bulletlist";
import GeneralModalShell from "../../atoms/GeneralModalShell";
import Price from "../../atoms/Price";

import labels from "./labels";

export { default as labels } from "./labels";

const CouponBG = styled(Image)`
  height: 50px;
`;

const MiniCouponWrapper = styled.div`
  position: relative;
  display: inline-flex;

  margin-bottom: ${({ noMargin, theme: { padding } }) =>
    noMargin ? 0 : padding[1]};
`;
const MiniCouponPrice = styled(Price).attrs({ size: "xs" })`
  display: block;
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.8);
`;

const CouponText = styled.span`
  position: relative;
  top: -6px; /* I know, spacing by theme - but 4 are too less and 8 is too high  */
  left: ${({ theme: { space } }) => space.xs}px;
`;

/**
 * Utility component to show a `<Price>` superimposed on an image
 */
export const MiniCoupon = ({ price, noMargin }) => (
  <MiniCouponWrapper noMargin={noMargin}>
    <CouponBG src="/img/mm-neu/geschenk-coupon.png" alt={""} />
    <MiniCouponPrice price={price} variant="secondary" />
  </MiniCouponWrapper>
);

MiniCoupon.propTypes = {
  /**
   * The amount of the coupon, will be given to `<Price>`
   */
  price: PropTypes.number.isRequired,
  noMargin: PropTypes.bool,
};
MiniCoupon.defaultProps = {
  noMargin: false,
};

/**
 * A component to show a coupon for use on product detail pages
 */
const DetailsCoupon = ({
  value,
  labels: { headline, terms, detailsToggle, details },
}) => {
  return value ? (
    <BorderBox>
      <Row>
        <Surface xs={24} sm={12}>
          <p>
            <PlusBubble />
            <CouponText>{`${headline} ${value},-`}</CouponText>
          </p>
        </Surface>
      </Row>
      <Row>
        <Col xs={24} sm={7} md={5}>
          <MiniCoupon price={value} />
        </Col>
        <Col xs={24} sm={15} md={17}>
          <Bulletlist list={terms} />
          <GeneralModalShell
            opener={<span className="tariffDetail">{detailsToggle}</span>}
            trackingObj={{
              eventAction: "click",
              eventCategory: "Link",
              eventLabel: "Coupondetails",
            }}
            ariaLabel="Coupondetails"
          >
            {details}
          </GeneralModalShell>
        </Col>
      </Row>
    </BorderBox>
  ) : null;
};

DetailsCoupon.propTypes = {
  /**
   * If false, no coupon exists and nothing will be rendered,
   * otherwise this is the amount of the coupon
   */
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,

  /**
   * Texts to customize the contents of the teaser
   *
   * You need to provide all labels in case of customization.
   * The default texts are exported as "labels"
   */
  labels: PropTypes.exact({
    /**
     * Title of the coupon
     */
    headline: PropTypes.string,
    /**
     * The bullet list of terms
     */
    terms: PropTypes.arrayOf(PropTypes.node),
    /**
     * The text of the button to show the details modal
     */
    detailsToggle: PropTypes.string,
    /**
     * The contents of the details modal
     */
    details: PropTypes.node,
  }),
};

DetailsCoupon.defaultProps = { labels };

export default DetailsCoupon;
