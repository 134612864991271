import { useQuery } from "react-query";
import { extendURLParams } from "@/utils/fetchData";
export const useCid = (options) => {
    const { tenant, environment } = extendURLParams();
    return useQuery(["useCid", tenant, environment], () => fetch("/helper/cid", {
        method: "GET",
        credentials: "include",
    }).then(async (res) => {
        const data = await res.json();
        if (!res.ok || data.error) {
            throw new Error(data.error);
        }
        return data;
    }), {
        ...options,
        cacheTime: 0,
        refetchOnWindowFocus: false,
    });
};
