import React from "react";
import PropTypes from "prop-types";

import { Headline } from "@mm/ui";

export const ProductHeadline = ({
  variant,
  hardware: { manufacturer, name },
}) => (
  <Headline variant={variant} data-qa="nameDevice">
    {manufacturer} {name}
  </Headline>
);

ProductHeadline.propTypes = {
  variant: PropTypes.string.isRequired,
  hardware: PropTypes.shape({
    manufacturer: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProductHeadline;
