import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { GeneralModalShell, DetailsAccordion } from "@/components/atoms";
import dataLayer from "@/helper/dataLayer";
import TariffDetailModal from "../TariffDetailModal";
import AccessoryDetailModal from "../AccessoryDetailModal";
import { extendURLParams } from "@/utils/fetchData";
const { tenant } = extendURLParams();
const modalCouponContent = {
    descriptions: [
        {
            headline: "Details zum Geschenk-Coupon",
            text: "Der Geschenk-Coupon gewährt einen einmaligen Preisnachlass i.H. des in der jeweiligen E-Mail hinterlegten Coupon-Wertes. Das jeweilige Gültigkeitsdatum ist auf dem Coupon abgedruckt. Einlösbar in einem Media Markt in Deutschland (online nicht einlösbar) ab einem Mindesteinkaufswert in Höhe des Couponwerts und nur gegen Vorlage des ausgedruckten Coupons (keine elektronische Vorlage).",
        },
        {
            headline: "Einschränkungen",
            text: "Keine Barauszahlung. Nicht kombinierbar mit anderen Aktionen. Nicht einlösbar für: Verträge mit Drittanbietern, sämtliche Download-/Content-/GamingCards, Gutschein(-karten/-boxen), Prepaid-Aufladekarten/-Services, E-Books/Bücher, Zusatzgarantien, Lieferservice, Reparaturdienstleistungen, Fotoservice, Lebensmittel, IQOS, E-Zigaretten, Heets, Liquids und Tchibo Cafissimo Produkte.",
        },
        {
            headline: "Versand",
            text: "Nach Ablauf der Widerrufsfrist des abgeschlossenen Mobilfunk-, Strom/Gas- , oder DSL/Kabelvertrages wird Ihnen Ihr Geschenk-Coupon, gestückelt in Teilbeträge, an die bei der Bestellung angegebene E-Mail Adresse zugesendet. Bitte überprüfen Sie regelmäßig Ihr E-Mail Postfach und Ihren SPAM-Ordner.",
        },
    ],
};
const modalCouponContentSaturn = {
    descriptions: [
        {
            headline: "Details zum Geschenk-Coupon",
            text: "Der Geschenk-Coupon gewährt einen einmaligen Preisnachlass i.H. des in der jeweiligen E-Mail hinterlegten Coupon-Wertes. Das jeweilige Gültigkeitsdatum ist auf dem Coupon abgedruckt. Einlösbar in einem Saturn Markt in Deutschland (online nicht einlösbar) ab einem Mindesteinkaufswert in Höhe des Couponwerts und nur gegen Vorlage des ausgedruckten Coupons (keine elektronische Vorlage).",
        },
        {
            headline: "Einschränkungen",
            text: "Keine Barauszahlung. Nicht kombinierbar mit anderen Aktionen. Nicht einlösbar für: Verträge mit Drittanbietern, sämtliche Download-/Content-/GamingCards, Gutschein(-karten/-boxen), Prepaid-Aufladekarten/-Services, E-Books/Bücher, Zusatzgarantien, Lieferservice, Reparaturdienstleistungen, Fotoservice, Lebensmittel, IQOS, E-Zigaretten, Heets, Liquids und Tchibo Cafissimo Produkte.",
        },
        {
            headline: "Versand",
            text: "Nach Ablauf der Widerrufsfrist des abgeschlossenen Mobilfunk-, Strom/Gas- , oder DSL/Kabelvertrages wird Ihnen Ihr Geschenk-Coupon, gestückelt in Teilbeträge, an die bei der Bestellung angegebene E-Mail Adresse zugesendet. Bitte überprüfen Sie regelmäßig Ihr E-Mail Postfach und Ihren SPAM-Ordner.",
        },
    ],
};
const DetailsLinkList = styled("div") `
  margin-bottom: 20px;

  > * {
    display: inline-block;
    margin-right: 5px;
  }

  a,
  button {
    color: rgb(0, 0, 0);

    &:visited {
      color: rgb(0, 0, 0);
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.max}px) {
    min-height: 42px;
  }
`;
const DetailsLink = styled("a") `
  font-size: 12px;
`;
const DetailsLinks = ({ coupon, tariff, accessories = [], }) => {
    const eventLabel = (detailLink) => `Provider:${tariff.serviceProvider}:Tariff:${tariff.name}:DetailLink:${detailLink}`;
    return (_jsxs(DetailsLinkList, { children: [_jsx(GeneralModalShell, { opener: _jsx("span", { children: "Tarifdetails" }), trackingObj: {
                    event: "gaEvent",
                    eventCategory: "Details",
                    eventAction: "click",
                    eventLabel: eventLabel("Tarif-Details"),
                }, children: _jsx(TariffDetailModal, { tariff: tariff }) }), coupon?.value && coupon?.value > 0 && (_jsx(GeneralModalShell, { opener: _jsx("span", { children: "Coupon - Details" }), trackingObj: {
                    event: "gaEvent",
                    eventCategory: "Details",
                    eventAction: "click",
                    eventLabel: eventLabel("Coupon-Details"),
                }, children: _jsx(DetailsAccordion, { details: tenant === "mediamarkt"
                        ? modalCouponContent
                        : modalCouponContentSaturn }) })), tariff.pibUrl && (_jsx(DetailsLink, { rel: "noreferrer", href: tariff.pibUrl, target: "_blank", onClick: () => {
                    dataLayer({
                        event: "gaEvent",
                        eventCategory: "Details",
                        eventAction: "click",
                        eventLabel: eventLabel("Produktdatenblatt"),
                    });
                }, "aria-label": "Produktdatenblatt \u00F6ffnet in neuem Tab", children: "Produktdatenblatt" })), accessories.length >= 1 && (_jsx(GeneralModalShell, { opener: _jsx("span", { children: "Pr\u00E4miendetails" }), trackingObj: {
                    event: "gaEvent",
                    eventCategory: "Details",
                    eventAction: "click",
                    eventLabel: eventLabel("Prämiendetails"),
                }, children: _jsx(AccessoryDetailModal, { accessory: accessories[0] }) }))] }));
};
DetailsLinks.propTypes = {};
export default DetailsLinks;
