import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "@core/text";
import dataLayer from "helper/dataLayer";

import Arrow from "@core/svgs/arrow.svg";
import Xsvg from "@core/svgs/icon-x.svg";

import { Pagelogo, OrderHotline, HorizontalDivider } from "@/components/atoms";
import { FlyoutSections } from "@/components/molecules";

import Anchor from "./Anchor";

const LogoContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0.5rem;
`;

const CloseButton = styled("button")`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  height: 3rem;
  width: 3rem;
  padding: 0;
`;

const ListWrapper = styled("ul")`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const ListElement = styled("li")`
  margin: 0;
`;

const FlyoutButton = styled("button")`
  background: none;
  border: none;
  color: ${(props) => props.theme.colors.black};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-family: Source Sans Pro, sans-serif;
  padding: 0.75rem 0.5rem;
  text-decoration: none;
  width: 100%;
  :active {
    background-color: ${(props) => props.theme.colors.grey[1]};
  }
  ${"svg"} {
    height: 1.25rem;
    &.arrowRight {
      padding: 0 0 0 0.25rem;
      margin: 0rem 0px auto auto;
      transform: scale(0.7) rotate(90deg);
      width: 1.25rem;
    }
    &.arrowLeft {
      margin: -0.25rem 0.25rem 0 0;
      transform: scale(0.7) rotate(270deg);
      width: auto;
    }
  }
`;

const FlyoutLayer = styled("div")`
  background: ${(props) => props.theme.colors.white};
  margin-left: 1rem;
  top: 7rem;
  width: 19rem;
  z-index: 102;
  display: flex;
  flex-direction: column;
  flex: 0 0 19rem;
  height: 0;
  &.active {
    overflow-y: initial;
    height: fit-content;
  }
`;

const MainLayer = styled.div`
  overflow-y: initial;
  flex: 0 0 19rem;
  &.translated {
    overflow: hidden;
  }
`;

const StyledNav = styled.nav`
  display: flex;
  width: 42rem;
  transition: transform 0.25s ease-in-out;
  &.translated {
    transform: translateX(-20rem);
  }
`;

const Layer = styled("div")`
  z-index: 101;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 21rem;
  transform: translateX(-21rem);
  overflow: hidden auto;
  transition: transform 0.25s ease-in-out;
  background: ${(props) => props.theme.colors.white};
  border-radius: 0 1.5rem 1.5rem 0;
  padding: 1.5rem 1rem;

  &.active {
    transform: translateX(0);
  }
`;

const MobileNavigation = ({ navigation = [], closeNavi, isOpen }) => {
  const intl = useIntl();
  {
    /**
     * ToDo: The flyout state should be moved to his own context
     */
  }

  const [flyoutId, setFlyoutId] = useState("");
  const [showFlyout, setShowFlyout] = useState(false);

  return (
    <Layer className={isOpen && "active"}>
      <LogoContainer>
        <Pagelogo href="/" color="black" showSubline variant="navMob" />
        <CloseButton
          onClick={() => closeNavi()}
          aria-label="Mobile Navigation schließen"
        >
          <Xsvg />
        </CloseButton>
      </LogoContainer>
      <HorizontalDivider />
      <StyledNav className={showFlyout && "translated"}>
        <MainLayer className={showFlyout && "translated"} aria-label="Main">
          {navigation.length > 1 && (
            <>
              <ListWrapper>
                {navigation.map(
                  ({ name, linkUrl, hasFlyout, category, id }) => (
                    <ListElement key={name}>
                      {hasFlyout ? (
                        <FlyoutButton
                          onClick={() => (
                            setShowFlyout(!showFlyout),
                            setFlyoutId(id),
                            dataLayer({
                              eventCategory: "Top Navigation",
                              eventAction: "click",
                              eventLabel: `Open:Flyout:${name}`,
                            })
                          )}
                          className="flyout"
                          aria-haspopup="true"
                        >
                          <span>{name}</span>
                          <Arrow className="arrowRight" />
                        </FlyoutButton>
                      ) : (
                        <Anchor
                          href={linkUrl}
                          onClick={() =>
                            dataLayer({
                              eventCategory: `${category}`,
                              eventAction: `click`,
                              eventLabel: `${name}:${linkUrl}`,
                            })
                          }
                        >
                          {name}
                        </Anchor>
                      )}
                    </ListElement>
                  )
                )}
              </ListWrapper>
              <HorizontalDivider />
            </>
          )}
          <ListWrapper>
            <ListElement>
              <OrderHotline className="navMob" />
            </ListElement>
            <ListElement>
              <Anchor
                href="/faq/"
                onClick={() =>
                  dataLayer({
                    eventCategory: "Top Navigation",
                    eventAction: "click",
                    eventLabel: "FAQ",
                  })
                }
              >
                Hilfe & Service
              </Anchor>
            </ListElement>
            <ListElement>
              <Anchor
                href={intl.formatMessage({
                  id: "url.main.shop",
                })}
                onClick={() =>
                  dataLayer({
                    eventCategory: "Top Navigation",
                    eventAction: "click",
                    eventLabel: "zum Onlineshop",
                  })
                }
              >
                <FormattedMessage id="app.backToMainShop" />
              </Anchor>
            </ListElement>
          </ListWrapper>
        </MainLayer>
        {navigation.length > 1 && (
          <FlyoutLayer
            className={showFlyout && "active"}
            aria-hidden={!showFlyout ? "true" : "false"}
            aria-expanded={showFlyout ? "true" : "false"}
            aria-label="navigation flyout"
          >
            {navigation
              .filter((item) => item.hasFlyout === true && item.id === flyoutId)
              .map(({ name, linkUrl, sections }) => (
                <React.Fragment key={name}>
                  <FlyoutButton
                    onClick={() => (
                      setShowFlyout(!showFlyout),
                      setTimeout(() => setFlyoutId(""), 300),
                      dataLayer({
                        eventCategory: "Flyout",
                        eventAction: "click",
                        eventLabel: `Close:Flyout:${name}`,
                      })
                    )}
                    className="navMobile"
                  >
                    <Arrow className="arrowLeft" />
                    Zurück zur Übersicht
                  </FlyoutButton>
                  <FlyoutSections
                    topName={name}
                    topUrl={linkUrl}
                    sections={sections}
                    layoutVariant="navMobile"
                  />
                </React.Fragment>
              ))}
          </FlyoutLayer>
        )}
      </StyledNav>
    </Layer>
  );
};

MobileNavigation.propTypes = {
  closeNavi: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      mobileText: PropTypes.string,
      text: PropTypes.string,
      linkUrl: PropTypes.string,
    })
  ).isRequired,
};

export default MobileNavigation;
