import React from "react";
import PropTypes from "prop-types";

import { Accordion, Surface, Col, Row, Headline } from "@mm/ui";

import { ServiceTile } from "components/atoms";

import "./style.scss";

const ServiceSection = ({ headline, seo, teaserContent }) => (
  <Surface variant="narrow" mt={[4, 4, 4, 6, 6]}>
    {teaserContent && [
      // eslint-disable-next-line react/jsx-key
      <Headline variant="h2" mb={6}>
        {headline}
      </Headline>,
      // eslint-disable-next-line react/jsx-key
      <Row>
        <Col xs={24} md={12} lg={7}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <ServiceTile {...teaserContent[0]} />
        </Col>
        <Col xs={24} md={12} lg={17}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <ServiceTile {...teaserContent[1]} />
        </Col>
      </Row>,
    ]}
    <h2 className="msd-service-section__headline">{seo.headline}</h2>
    <p className="msd-service-section__intro">{seo.intro}</p>
    <Accordion multiOpen entryList={seo.entryList} variant="service" />
  </Surface>
);

export const seoPropTypes = PropTypes.shape({
  headline: PropTypes.string.isRequired,
  intro: PropTypes.string.isRequired,
  entryList: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired,
      content: PropTypes.node.isRequired,
    })
  ),
});

ServiceSection.propTypes = {
  headline: PropTypes.string,
  seo: seoPropTypes.isRequired,
  teaserContent: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
      link: PropTypes.string.isRequired,
      className: PropTypes.string,
    })
  ),
};

ServiceSection.defaultProps = {
  teaserContent: null,
  headline: null,
};

export default ServiceSection;
