import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { CheckoutSection, CheckoutSectionHeadline, CheckoutSectionText, RadioList, } from "../components";
import { useCheckout, useCountries, useIdCardNumber } from "../hooks/api";
import styled from "styled-components";
import { InputComboBox, InputText } from "@mm/ui";
import { transformDateString } from "@/utils/transformDateString";
import { useField, useValidation, ValidateOnEvent, } from "@/pages/CheckoutPage/hooks";
import { ACTIONS, useCheckoutState, } from "@/pages/CheckoutPage/context/CheckoutContext";
import { IdentificationMode } from "../types";
import { extendURLParams } from "@/utils/fetchData";
// TODO: refactor Row and wrappers to shared components
const Row = styled.div `
  display: flex;
  width: 100%;
  gap: 1.5rem;
  @media (max-width: 760px) {
    flex-wrap: wrap;
  }
`;
// TODO: rename wrappers
const FullWidthWrapper = styled.div `
  flex: 1;
  @media (max-width: 760px) {
    flex: 1 0 100%;
  }
`;
const Icon = styled.i `
  cursor: pointer;
`;
const Image = styled.img `
  max-width: 100%;
`;
export const IdentificationSection = ({ defaultValues, options, schema, }) => {
    const { cdnUrl } = extendURLParams();
    const { state, dispatch } = useCheckoutState();
    const { isLoading, isFetching } = useCheckout();
    const { clearErrors, } = useValidation();
    const { errors: birthDayErrors, fieldState: birthDay, setFieldState: setBirthDay, trigger: triggerBirthDay, } = useField("identificationBirthday", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: birthPlaceErrors, fieldState: birthPlace, setFieldState: setBirthPlace, trigger: triggerBirthPlace, } = useField("identificationBirthplace", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: nationalityErrors, fieldState: nationality, setFieldState: setNationality, } = useField("nationality", schema, "", {
        validateOn: ValidateOnEvent.Change,
    });
    const { errors: idCardExpirationDateErrors, fieldState: idCardExpirationDate, setFieldState: setIdCardExpirationDate, trigger: triggerIdCardExpirationDate, } = useField("idCardExpirationDate", schema, "", {
        validateOn: ValidateOnEvent.Manual,
        forceContextState: true,
    });
    const { errors: idCardNumberErrors, fieldState: idCardNumber, setFieldState: setIdCardNumber, trigger: triggerIdCardNumber, } = useField("idCardNumber", schema, "", {
        validateOn: ValidateOnEvent.Manual,
        forceContextState: true,
    });
    const { errors: passportExpirationDateErrors, fieldState: passportExpirationDate, setFieldState: setPassportExpirationDate, trigger: triggerPassportExpirationDate, } = useField("passportExpirationDate", schema, "", {
        validateOn: ValidateOnEvent.Manual,
        forceContextState: true,
    });
    const { errors: passportNumberErrors, fieldState: passportNumber, setFieldState: setPassportNumber, } = useField("passportNumber", schema, "", {
        validateOn: ValidateOnEvent.Change,
        forceContextState: true,
    });
    const { fieldState: identificationMode, setFieldState: setIdentificationMode, } = useField("identificationMode", schema, state?.identificationMode ??
        defaultValues?.identificationMode ??
        IdentificationMode.idCard, {
        validateOn: ValidateOnEvent.Change,
    });
    const { data: countries, refetch: refetchCountries, isRefetching: countriesFetching, } = useCountries();
    const { isFetching: idCardIsFetching, isLoading: idCardIsLoading, refetch: refetchIdCard, } = useIdCardNumber(idCardNumber);
    const [allowIdCardSelection, setAllowIdCardSelection] = useState(state?.nationality ? state.nationality == "Deutschland" : true);
    useEffect(() => {
        if (!countries?.length) {
            refetchCountries();
        }
    }, []);
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: options?.title ?? "Ausweisdaten" }), options?.introText && (_jsx(CheckoutSectionText, { children: options.introText })), _jsxs(Row, { children: [_jsx(FullWidthWrapper, { children: _jsx(InputText, { id: "identificationBirthday", label: "Geburtsdatum (TT.MM.JJJJ)*", value: birthDay ?? "", message: birthDayErrors?.[0]?.message, layout: birthDayErrors && "error", onKeyDown: (event) => {
                                const { selectionStart, selectionEnd } = event.target;
                                if (event.code && event.code === "Backspace") {
                                    event.preventDefault();
                                    if (birthDay && birthDay.length > 0) {
                                        if (selectionStart !== selectionEnd) {
                                            setBirthDay(birthDay.slice(0, selectionStart ?? undefined) +
                                                birthDay.slice(selectionEnd ?? undefined));
                                            return;
                                        }
                                        setBirthDay(birthDay.slice(0, selectionStart ? selectionStart - 1 : undefined) + birthDay.slice(selectionStart ?? undefined));
                                    }
                                    return;
                                }
                            }, onChange: (value) => {
                                setBirthDay(transformDateString(value));
                            }, onBlur: () => triggerBirthDay(), disabled: isLoading || isFetching, autoComplete: "none", qaName: "identification_dob" }) }), _jsx(FullWidthWrapper, { children: _jsx(InputText, { id: "identificationBirthplace", label: "Geburtsort*", value: birthPlace ?? "", message: birthPlaceErrors?.[0]?.message, layout: birthPlaceErrors && "error", onChange: (value) => {
                                setBirthPlace(value);
                            }, onBlur: () => triggerBirthPlace(), disabled: isLoading || isFetching, autoComplete: "none", qaName: "identification_city" }) })] }), _jsx(Row, { children: _jsx(FullWidthWrapper, { children: _jsx(InputComboBox, { id: "nationality", label: "Nationalit\u00E4t*", value: nationality, message: nationalityErrors?.[0]?.message, layout: nationalityErrors && "error", onChange: (value) => {
                            setNationality(value);
                            if (value !== "" && value !== "Deutschland") {
                                setAllowIdCardSelection(false);
                                setIdentificationMode(IdentificationMode.passport);
                                dispatch({
                                    type: ACTIONS.RESET_FIELDS,
                                    payload: ["idCardExpirationDate", "idCardNumber"],
                                });
                            }
                            else {
                                setAllowIdCardSelection(true);
                            }
                            clearErrors([
                                "idCardExpirationDate",
                                "idCardNumber",
                                "passportExpirationDate",
                                "passportNumber",
                            ]);
                        }, disabled: isLoading || isFetching || countriesFetching, items: countries ? countries.map(({ name }) => name) : [], autoComplete: "none", qaName: "identification_nationality" }) }) }), _jsx(RadioList, { checked: identificationMode === IdentificationMode.idCard
                    ? "identificationMode--idCard"
                    : "identificationMode--passport", id: "identificationMode", items: [
                    ...(allowIdCardSelection
                        ? [
                            {
                                key: "identificationMode--idCard",
                                label: "Personalausweis",
                            },
                        ]
                        : []),
                    {
                        key: "identificationMode--passport",
                        label: "Reisepass",
                    },
                ], onChange: (key) => {
                    clearErrors([
                        "idCardExpirationDate",
                        "idCardNumber",
                        "passportExpirationDate",
                        "passportNumber",
                    ]);
                    if (key === "identificationMode--idCard") {
                        dispatch({
                            type: ACTIONS.RESET_FIELDS,
                            payload: ["passportExpirationDate", "passportNumber"],
                        });
                    }
                    else {
                        dispatch({
                            type: ACTIONS.RESET_FIELDS,
                            payload: ["idCardExpirationDate", "idCardNumber"],
                        });
                    }
                    setIdentificationMode(key === "identificationMode--idCard"
                        ? IdentificationMode.idCard
                        : IdentificationMode.passport);
                }, disabled: isLoading || isFetching }), identificationMode === IdentificationMode.idCard && (_jsxs(_Fragment, { children: [_jsxs(Row, { children: [_jsx(FullWidthWrapper, { children: _jsx(InputText, { id: "idCardExpirationDate", label: "Ablauf des Dokuments (TT.MM.JJJJ)*", value: idCardExpirationDate, message: idCardExpirationDateErrors?.[0]?.message, layout: idCardExpirationDateErrors && "error", onKeyDown: (event) => {
                                        const { selectionStart, selectionEnd } = event.target;
                                        if (event.code && event.code === "Backspace") {
                                            event.preventDefault();
                                            if (idCardExpirationDate &&
                                                idCardExpirationDate.length > 0) {
                                                if (selectionStart !== selectionEnd) {
                                                    setIdCardExpirationDate(idCardExpirationDate.slice(0, selectionStart ?? undefined) +
                                                        idCardExpirationDate.slice(selectionEnd ?? undefined));
                                                    return;
                                                }
                                                setIdCardExpirationDate(idCardExpirationDate.slice(0, selectionStart ? selectionStart - 1 : undefined) +
                                                    idCardExpirationDate.slice(selectionStart ?? undefined));
                                            }
                                            return;
                                        }
                                    }, onChange: (value) => {
                                        setIdCardExpirationDate(transformDateString(value));
                                    }, onBlur: () => triggerIdCardExpirationDate(), disabled: isLoading || isFetching, autoComplete: "none", qaName: "id-card_exd" }) }), _jsx(FullWidthWrapper, { children: _jsx(InputText, { id: "idCardNumber", label: "Ausweisnummer*", value: idCardNumber, message: idCardNumberErrors?.[0]?.message, layout: idCardNumberErrors && "error", onChange: (value) => {
                                        setIdCardNumber(value);
                                    }, onBlur: () => {
                                        if (idCardNumber) {
                                            refetchIdCard().then((result) => {
                                                if (!result.data?.error) {
                                                    triggerIdCardNumber();
                                                }
                                            });
                                        }
                                    }, disabled: isLoading || isFetching || idCardIsLoading || idCardIsFetching, autoComplete: "none", qaName: "id-card_number" }) })] }), _jsx(CheckoutSectionText, { children: "Du findest die Ausweisnummer auf der R\u00FCckseite deines Personalausweises." }), _jsx(Image, { src: `${cdnUrl}/img/personalausweis.jpg`, alt: "Ausweisnummer" })] })), identificationMode === "passport" && (_jsxs(Row, { children: [_jsx(FullWidthWrapper, { children: _jsx(InputText, { id: "passportExpirationDate", label: "Ablauf des Dokuments (TT.MM.JJJJ)*", value: passportExpirationDate, message: passportExpirationDateErrors?.[0]?.message, layout: passportExpirationDateErrors && "error", onKeyDown: (event) => {
                                const { selectionStart, selectionEnd } = event.target;
                                if (event.code && event.code === "Backspace") {
                                    event.preventDefault();
                                    if (passportExpirationDate &&
                                        passportExpirationDate.length > 0) {
                                        if (selectionStart !== selectionEnd) {
                                            setPassportExpirationDate(passportExpirationDate.slice(0, selectionStart ?? undefined) +
                                                passportExpirationDate.slice(selectionEnd ?? undefined));
                                            return;
                                        }
                                        setPassportExpirationDate(passportExpirationDate.slice(0, selectionStart ? selectionStart - 1 : undefined) +
                                            passportExpirationDate.slice(selectionStart ?? undefined));
                                    }
                                    return;
                                }
                            }, onChange: (value) => {
                                setPassportExpirationDate(transformDateString(value));
                            }, onBlur: () => triggerPassportExpirationDate(), disabled: isLoading || isFetching, autoComplete: "none", qaName: "passport_exd" }) }), _jsx(FullWidthWrapper, { children: _jsx(InputText, { id: "passportNumber", label: "Ausweisnummer*", value: passportNumber, message: passportNumberErrors?.[0]?.message, layout: passportNumberErrors && "error", onChange: (value) => {
                                setPassportNumber(value);
                            }, disabled: isLoading || isFetching, autoComplete: "none", qaName: "passport_number" }) })] }))] }));
};
