import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import PlusBubble from "@core/svgs/plus-bubble.svg";

import AccessoryDetailsModal from "components/organisms/AccessoryDetailsModal";

import { accessoryPropTypes } from "sharedPropTypes";

const Section = styled("div")`
  padding-top: ${({ theme: { space } }) => `${space.xl}px`};
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: auto 1fr auto;
  gap: 0.5em;
  @media (max-width: 390px) {
    grid-template-rows: 1fr auto auto;
    grid-template-columns: auto 1fr;
  }
`;

const Headline = styled("div")`
  padding-top: 0.1em; // Even 4px is to big, 0.1em is 1.6 px...

  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 2;
  grid-column-end: 4;

  @media ((min-width: ${({ theme }) => theme.breakpointsMap.sm.min}px) and 
        (max-width: ${({ theme }) => theme.breakpointsMap.sm.max}px)),
    (min-width: ${({ theme }) => theme.breakpointsMap.lg.min}px) {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 3;
  }

  @media (max-width: 390px) {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 3;
  }
`;

const StyledBonusImage = styled("div")`
  flex: 1;
  height: ${({ theme: { space } }) => `${space.xxlhuge + space.xl}px`};
  margin: auto;

  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 3;
  grid-column-end: 4;

  @media ((min-width: ${({ theme }) => theme.breakpointsMap.sm.min}px) and 
        (max-width: ${({ theme }) => theme.breakpointsMap.sm.max}px)),
    (min-width: ${({ theme }) => theme.breakpointsMap.lg.min}px) {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 3;
    grid-column-end: 4;
  }

  @media (max-width: 390px) {
    margin: ${({ theme: { space } }) => `${space.s}px`} 0;

    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
  }
`;

const BonusPrice = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 2;
  grid-column-end: 3;

  @media (max-width: 390px) {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 2;
    grid-column-end: 3;
  }
`;

const FixedWidth = styled("div")({
  order: "0",
  width: "auto",
  gridColumnStart: "1",
  gridColumnEnd: "2",
});

const StyledSpan = styled("span")`
  font-size: 16px;
`;

const SelectedPkBonuses = ({ accessory, ebootisId, tariff }) => {
  return (
    <>
      <Section>
        <FixedWidth>
          <PlusBubble />
        </FixedWidth>
        <Headline>
          <StyledSpan>{`${accessory.name}`}</StyledSpan>
          <br />
          <AccessoryDetailsModal accessory={accessory} tariff={tariff} />
        </Headline>
        <BonusPrice>
          {accessory.extendedVariants[0].variant.pkCouponValue > 0 && (
            <StyledSpan>
              Zuzahlung Prämie:&nbsp;
              {accessory.extendedVariants[0].variant.pkCouponValue}€
            </StyledSpan>
          )}
        </BonusPrice>
        <StyledBonusImage>
          <AccessoryDetailsModal
            accessory={accessory}
            ebootisId={ebootisId}
            tariff={tariff}
          />
        </StyledBonusImage>
      </Section>
    </>
  );
};

SelectedPkBonuses.propTypes = {
  accessory: PropTypes.shape(accessoryPropTypes),
  ebootisId: PropTypes.string,
};

SelectedPkBonuses.defaultProps = {
  accessory: null,
  ebootisId: null,
};

export default SelectedPkBonuses;
