/**
 * If a float is given as input: returns comma delimited string
 * If an integer is given as input: just return the input
 *
 * @param value
 */
export const transformNumberStringToCommaDelimitedString = (value) => {
    if (!isNaN(Number(value)) && value.toString().indexOf(".") != -1) {
        return value.replace(".", ",");
    }
    return value;
};
