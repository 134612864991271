import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { useCheckoutState } from "@/pages/CheckoutPage/context/CheckoutContext";
import { CheckoutSection, CheckoutSectionText, } from "@/pages/CheckoutPage/components";
const StyledCheckoutSection = styled(CheckoutSection) `
  width: 100%;
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 760px) {
    margin-top: 1rem;
    margin-bottom: 0;
  }
`;
export const MarketCodeDisplay = () => {
    const { state: { promotionCode }, } = useCheckoutState();
    if (!promotionCode) {
        return null;
    }
    return (_jsx(StyledCheckoutSection, { children: _jsxs(CheckoutSectionText, { children: ["Markt-ID: ", _jsx("strong", { children: promotionCode })] }) }));
};
