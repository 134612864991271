import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CheckoutSection, CheckoutSectionHeadline, CheckoutSectionText, RadioList, } from "@/pages/CheckoutPage/components";
import { PaymentType } from "@/pages/CheckoutPage/types";
import { useField, ValidateOnEvent } from "@/pages/CheckoutPage/hooks";
import { useCheckoutState } from "@/pages/CheckoutPage/context/CheckoutContext";
import { useCheckout } from "@/pages/CheckoutPage/hooks/api";
import { formatNumberAsCurrencyString } from "@/utils/price/formatNumberAsCurrencyString";
import { Spinner } from "@/components/atoms";
import { extendURLParams } from "@/utils/fetchData";
import styled from "styled-components";
const LabelImage = styled.img `
  height: 1.5rem;
  width: auto;
  margin-left: 0.5rem;
`;
export const DeliveryPaymentSection = ({ defaultValues, options, schema, }) => {
    const { cdnUrl } = extendURLParams();
    const { data, isFetching, isLoading } = useCheckout();
    const { state } = useCheckoutState();
    const { fieldState: paymentType, setFieldState: setPaymentType } = useField("paymentType", schema, state?.paymentType ??
        defaultValues?.paymentType ??
        PaymentType.Paypal, { validateOn: ValidateOnEvent.Change });
    const deliveryPrice = data?.summary?.deliveryPrice?.webId
        ? formatNumberAsCurrencyString(data.summary.deliveryPrice.webId)
        : undefined;
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: options?.title ?? "Zahlung für den Versand" }), isFetching || isLoading ? (_jsx(Spinner, { color: "secondary", "data-qa": "spinner" })) : (_jsxs(_Fragment, { children: [_jsxs(CheckoutSectionText, { "data-qa": "deliveryPaymentText", children: ["Bitte beachte, dass der Versand deiner Bestellung erst erfolgen kann, sobald die Zahlung der Versandkosten", deliveryPrice ? ` von ${deliveryPrice}` : "", " bei uns eingegangen ist."] }), _jsx(RadioList, { checked: paymentType === PaymentType.Paypal
                            ? "paymentType--paypal"
                            : "paymentType--prepayment", id: "paymentType", items: [
                            {
                                key: "paymentType--paypal",
                                label: () => {
                                    return (_jsx(LabelImage, { src: `${cdnUrl}/img/mm-neu/paypal.svg`, alt: "PayPal" }));
                                },
                                qaName: "paymentTypePaypal",
                            },
                            {
                                key: "paymentType--prepayment",
                                label: () => {
                                    return (_jsx(LabelImage, { src: `${cdnUrl}/img/mm-neu/vorkasse.svg`, alt: "Vorkasse" }));
                                },
                                qaName: "paymentTypePrepayment",
                            },
                        ], onChange: (key) => {
                            setPaymentType(key === "paymentType--paypal"
                                ? PaymentType.Paypal
                                : PaymentType.Prepayment);
                        } })] }))] }));
};
