import PropTypes from "prop-types";
import styled from "styled-components";

const Label = styled.button`
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.grey[1]};
  border: 1px ${({ theme }) => theme.colors.grey[2]} solid;
  border-radius: ${({ theme }) => theme.borderRadius[0]};
  padding: ${({ theme }) => theme.padding[1]} ${({ theme }) => theme.padding[1]};
  font-size: ${({ theme }) => theme.legacyFontSize[1]};
  text-decoration: none;
  flex-shrink: 0;
`;

Label.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.arrayOf(PropTypes.node.isRequired),
  ]),
  onClick: PropTypes.func,
};

export default Label;
