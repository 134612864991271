import React from "react";
import { Box } from "@mm/ui";

import { Bulletlist, Eyecatchers, TileBox } from "components/atoms";
import { ProductAccessoryDisplay, Loading } from "components/molecules";

import {
  AffiliateOfferContainer,
  AffiliateOfferItem,
  StyledOfferHeadline,
} from "./AffiliateOffer.styled";

import PriceDisplayAffOffer from "./PriceDisplay";

const handleAffiliate = (trackingEvent) => {
  const trackingObj = {
    event: "gaEvent",
    eventCategory: "AffiliateOffer Element",
    eventAction: "click",
    eventLabel: `${trackingEvent}`,
  };
  window.dataLayer.push(trackingObj);
};

const AffiliateOffer = ({ data }) => {
  if (!data) return <Loading variant="manufacturer" />;

  return (
    <AffiliateOfferContainer>
      {data.map((item, index) => (
        <AffiliateOfferItem key={item.title.replace(" ", "_")}>
          {item.eyeCatcherOne && (
            <Eyecatchers
              eyecatchers={{
                small1: item.eyeCatcherOne,
                small2: item.eyeCatcherTwo,
              }}
              variant="affOffer"
            />
          )}
          <TileBox variant="tile">
            {item.title && (
              <Box mb={2}>
                <StyledOfferHeadline>{item.title}</StyledOfferHeadline>
              </Box>
            )}
            {item.imagePath && (
              <ProductAccessoryDisplay
                alt={item.title}
                name={item.title}
                manufacturer={item.title}
                singleImage={item.imagePath}
              />
            )}
            {item.bullets && (
              <Box mt={2}>
                <Bulletlist variant="checkmark" list={item.bullets} size="m" />
              </Box>
            )}
            <Box mt={2} marginTop="auto">
              <>
                <PriceDisplayAffOffer
                  AffOffer={item.legalNotes}
                  direction="column"
                  oldPrice={`${item.oldPrice}`}
                  oneTimePrice={item.oneTimePrice}
                  price={item.price}
                  priceNotice={item.priceNotice}
                />
                <a
                  href={item.affiliateUrl}
                  className="msd-submit-order-btn"
                  onClick={() => handleAffiliate(item.trackingEvent)}
                  aria-label={`${item.title} bestellen, externer Link öffnet sich in einem neuen Fenster`}
                  data-qa={`offer${index}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Zur Bestellung
                </a>
              </>
            </Box>
          </TileBox>
        </AffiliateOfferItem>
      ))}
    </AffiliateOfferContainer>
  );
};

export default AffiliateOffer;
