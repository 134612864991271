import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { tariffPropTypes } from "sharedPropTypes";
import dataLayer, { sanitizeUrl } from "helper/dataLayer";
import { GeneralModalShell } from "components/atoms";

import InlineLink from "../../atoms/InlineLink";

import TariffInfo from "../TariffInfo";

const StyledInlineLink = styled(InlineLink)`
  display: inline-block;
  font-size: ${({ theme }) => theme.legacyFontSize[2]};
  margin-left: 1em;
  margin-bottom: ${({ theme }) => theme.space.l}px;
  color: ${({ theme }) => theme.colors.shade[4]};
`;

const TariffDetailsLinks = ({
  tariff,
  tariff: { pibUrl, carrier },
  analytics: { context },
}) => (
  <>
    <GeneralModalShell
      opener={<span className="tariffDetail">Tarifdetails</span>}
      trackingObj={{
        eventCategory: "Produktteaser",
        eventAction: "click",
        eventLabel: `Tarifdetails:undefined:undefined:${context}`,
      }}
    >
      <TariffInfo tariff={tariff} carrier={carrier} />
    </GeneralModalShell>
    {pibUrl && (
      <StyledInlineLink
        href={`${pibUrl}`}
        target="_blank"
        onClick={() =>
          dataLayer({
            eventCategory: "Produktteaser",
            eventAction: "click",
            eventLabel: `Produktdatenblatt:${sanitizeUrl(
              pibUrl
            )}:undefined:${context}`,
          })
        }
        aria-label="Produktdatenblatt öffnet in neuem Tab"
      >
        Produktdatenblatt
      </StyledInlineLink>
    )}
  </>
);

TariffDetailsLinks.propTypes = {
  tariff: tariffPropTypes.isRequired,
  analytics: PropTypes.shape({
    context: PropTypes.string.isRequired,
  }).isRequired,
};

export default TariffDetailsLinks;
