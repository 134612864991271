import React from "react";
import PropTypes from "prop-types";

import "./style.scss";

const Accordion = ({ entryList, variant }) => {
  return (
    <div variant={variant} className="msd-accordion--wrapper">
      {entryList.map((entry, idx) => {
        const key = idx + 1;
        return (
          <details
            key={key}
            name={entry.header}
            className={`msd-accordion msd-accordion--${variant}`}
            open={entry.open}
          >
            <summary className="msd-accordion--summary">
              {entry.header}
              <span className="arrow"></span>
            </summary>
            {React.isValidElement(entry.content) ? (
              <div className="msd-accordion--content">{entry.content}</div>
            ) : (
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: entry.content,
                }}
                className="msd-accordion--content"
              />
            )}
          </details>
        );
      })}
    </div>
  );
};

Accordion.propTypes = {
  entryList: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired,
      content: PropTypes.node.isRequired,
      open: PropTypes.bool,
    })
  ).isRequired,
  variant: PropTypes.oneOf(["default", "footer"]),
};

Accordion.defaultProps = {
  multiOpen: false,
  activeKey: null,
  variant: "default",
  className: "",
};

export default Accordion;
