import React from "react";
import PropTypes from "prop-types";
import { Text } from "@mm/ui";
import "./style.scss";

const Breadcrumb = ({ items, hideHomeItem, isProductDetailInterface }) => {
  const linkedItems = items.map(({ text, link, url }) => ({
    label: text,
    link: link ? `/${link}` : url,
  }));

  const preparedItems = hideHomeItem
    ? linkedItems
    : [
        {
          label: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              baseProfile="tiny"
              viewBox="0 0 19 15"
              width="16"
              height="16"
            >
              <path
                fill="#000000"
                d="M16 8v7h-5v-4H8v4H3V8l6.5-4.3L16 8zm1-1L9.4 2 2 7H0l9.4-7L19 7h-2z"
                fillRule="evenodd"
              />
            </svg>
          ),
          link: "/",
        },
        ...linkedItems,
      ];

  return (
    <ul className="msd-bread-crumb__container">
      {preparedItems.map(({ link, label }, idx) => {
        const itemElemKey = `breadcrumbs-${idx}`;

        return (
          <li
            key={itemElemKey}
            className={
              isProductDetailInterface
                ? `msd-bread-crumb__item msd-bread-crumb__item--interface`
                : `msd-bread-crumb__item msd-bread-crumb__item--noInterface`
            }
          >
            {idx !== 0 && (
              <>
                <i className="msd-bread-crumb__item-icon msd-bread-crumb__item-icon--desktop icon-MM_iconfont_ArrowR" />
                <i className="msd-bread-crumb__item-icon msd-bread-crumb__item-icon--mobile icon-MM_iconfont_ArrowL" />
              </>
            )}

            <a
              className="msd-bread-crumb__item-link"
              href={
                // add preceding "/" to make paths relative to root dir
                link &&
                (idx === 0 ? link : `http://${window.location.host}${link}`)
              }
            >
              {typeof label === "string" ? <Text>{label}</Text> : label}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.node.isRequired,
      link: PropTypes.string,
    })
  ).isRequired,
  hideHomeItem: PropTypes.bool,
  isProductDetailInterface: PropTypes.bool,
};

Breadcrumb.defaultProps = {
  hideHomeItem: false,
  isProductDetailInterface: false,
};

export default React.memo(Breadcrumb);
