import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Headline, Box, Image, Flex } from "@mm/ui";

import styled from "styled-components";

import { accessoryPropTypes } from "sharedPropTypes";

import { useHardwareDetails } from "utils/fetchData";

import { GeneralModalShell, DetailsAccordion } from "components/atoms";

import dataLayer from "helper/dataLayer";

const RegularImg = styled(Image)({
  height: "100%",
  display: "inline-block",
});

const HowToGetThePkBonus = styled(Flex)`
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 10px;

  .HowToGetThePkBonus {
    &__image {
      display: block;
      margin: 0 auto;
    }

    &__imageWrapper {
      flex-shrink: 0;
      width: 60px;
    }

    &__text {
      flex-basis: 70%;
      padding-bottom: 10px;
      margin: 0;
    }
  }
`;

const HowToGetThePkBonusContent = (serviceProviderKey) => {
  switch (serviceProviderKey) {
    case "letsgo-mobile":
      return [
        {
          imgPath: `${window.CDN_URL}/img/HowToGetThePkBonusContent/search.svg`,
          text: "Um deinen Treuebonus zu erhalten, gehe einfach nach Erhalt deiner sechsten Rechnung auf https://vorteile-mediamarkt.de",
        },
        {
          imgPath: `${window.CDN_URL}/img/HowToGetThePkBonusContent/monitore.svg`,
          text: "Gib deinen persönlichen Code aus deiner Auftragsbestätigung ein.",
        },
        {
          imgPath: `${window.CDN_URL}/img/HowToGetThePkBonusContent/hook-square.svg`,
          text: "Wähle deine Prämie aus und lade deiner sechste Rechnung als Nachweis hoch.",
        },
        {
          imgPath: `${window.CDN_URL}/img/HowToGetThePkBonusContent/express-delivery.svg`,
          text: "Nach erfolgreicher Bestellung und positiver Prüfung werden wir dir deine Prämie schnellstmöglich zusenden.",
        },
      ];

    default:
      return [
        {
          imgPath: `${window.CDN_URL}/img/HowToGetThePkBonusContent/search.svg`,
          text: "Um deine Prämie zu erhalten, gehe einfach nach Erhalt deiner ersten Rechnung auf https://vorteile-mediamarkt.de",
        },
        {
          imgPath: `${window.CDN_URL}/img/HowToGetThePkBonusContent/monitore.svg`,
          text: "Gib deinen persönlichen Code aus deiner Auftragsbestätigung ein.",
        },
        {
          imgPath: `${window.CDN_URL}/img/HowToGetThePkBonusContent/hook-square.svg`,
          text: "Wähle deine Prämie aus und lade deine erste Rechnung als Nachweis hoch.",
        },
        {
          imgPath: `${window.CDN_URL}/img/HowToGetThePkBonusContent/express-delivery.svg`,
          text: "Nach erfolgreicher Bestellung und positiver Prüfung werden wir dir deine Prämie schnellstmöglich zusenden.",
        },
      ];
  }
};

const ModalContent = ({ accessory, ebootisId, images, tariff }) => {
  if (!accessory) return null;

  return (
    <>
      <div className="detailtext">
        <Headline variant="h2">
          {accessory.pkBonus ? "Prämiendetails" : "Zugabendetails"}
        </Headline>
      </div>
      {ebootisId && (
        <Box textAlign="center" marginY={2}>
          {!accessory.pkBonus && accessory.extendedVariants && (
            <RegularImg srcSet={images} />
          )}
          {accessory.pkBonus && <RegularImg srcSet={images} />}
        </Box>
      )}
      <DetailsAccordion details={accessory.details} />
      {accessory.pkBonus && (
        <div>
          {HowToGetThePkBonusContent(tariff.serviceProviderKey).map(
            (item, idx) => (
              <HowToGetThePkBonus alignItems="center" key={idx}>
                <div className="HowToGetThePkBonus__imageWrapper">
                  <img
                    className="HowToGetThePkBonus__image"
                    src={item.imgPath}
                    alt={item.text}
                  />
                </div>
                <p className="HowToGetThePkBonus__text">{item.text}</p>
              </HowToGetThePkBonus>
            )
          )}
        </div>
      )}
      {accessory.addresses && (
        <>
          <div className="msd-manufacturer-addresses-headline">
            <span>
              <b>Hersteller</b>
            </span>
          </div>
          <div className="msd-manufacturer-addresses-wrapper">
            {accessory.addresses.map((address) => (
              <div key={address.companyName} className="address">
                <div>{address.companyName}</div>
                <div>
                  <a
                    href={`mailto:${address.mail}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {address.mail}
                  </a>
                </div>
                <div>
                  <a href={address.website} target="_blank" rel="noreferrer">
                    {address.website}
                  </a>
                </div>
                <div>
                  <span>{address.street}</span>{" "}
                  <span>{address.streetNumber}</span>
                </div>
                <div>
                  <span>{address.zip}</span> <span>{address.city}</span>
                </div>
                <div>
                  <span>{address.country}</span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

ModalContent.defaultProps = {
  accessory: {},
  ebootisId: null,
  isTariff: false,
  tariff: null,
};

ModalContent.propTypes = {
  accessory: accessoryPropTypes,
  ebootisId: null,
  isTariff: false,
  tariff: null,
};

const handleTrackingClick = (evt) => {
  evt.preventDefault();
  dataLayer({
    eventCategory: "Link",
    eventAction: "click",
    eventLabel: "Accessorydetails:undefined:undefined:undefined",
  });
};

const AccessoryDetailsModal = ({
  id,
  ebootisId,
  accessory,
  isTariff,
  tariff,
}) => {
  const [modalDetails, setModalDetails] = useState(accessory);
  const { data: hardwareDetails } = useHardwareDetails(id, {
    enabled: !!id,
  });
  const variantsImages = accessory.extendedVariants.filter(
    (item) => item.variant.ebootisId === ebootisId
  )[0]?.variant.images[0];

  useEffect(() => {
    setModalDetails(hardwareDetails);
  }, [hardwareDetails]);

  return (
    <GeneralModalShell
      dynamicHeight
      opener={
        ebootisId && !isTariff ? (
          <>
            {!accessory.pkBonus && <RegularImg srcSet={variantsImages} />}
            {accessory.pkBonus && <RegularImg srcSet={variantsImages} />}
          </>
        ) : (
          <span onClick={handleTrackingClick}>
            {accessory.pkBonus ? "Prämiendetails" : "Zugabendetails"}
          </span>
        )
      }
    >
      <ModalContent
        accessory={modalDetails || accessory}
        ebootisId={ebootisId}
        images={variantsImages}
        tariff={tariff}
      />
    </GeneralModalShell>
  );
};

AccessoryDetailsModal.defaultProps = {
  accessory: {},
  ebootisId: null,
  isTariff: false,
  tariff: null,
};

AccessoryDetailsModal.propTypes = {
  accessory: accessoryPropTypes,
  ebootisId: PropTypes.string,
  isTariff: PropTypes.bool,
  tariff: PropTypes.object,
};

export default AccessoryDetailsModal;
