import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from "styled-components";
import { Surface } from "@mm/ui";
import { FlyoutSections } from "@/components/molecules";
const Flyout = styled("div") `
  position: absolute;
  z-index: -10;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 0 0 2rem 2rem;
  transition: all 500ms ease;

  ${({ showFlyout }) => showFlyout &&
    css `
      transform: translateY(100%);
    `}
  :hover {
    transform: translateY(100%);
  }
`;
const LayoutDesktop = styled(Surface) `
  display: flex;
  flex-direction: row;
  padding: 3rem 0;
`;
export const FlyoutDesktop = ({ id, name, linkUrl, sections, flyoutId, }) => {
    return (_jsx(Flyout, { showFlyout: flyoutId === id, "aria-hidden": flyoutId !== id ? "true" : "false", "aria-expanded": flyoutId === id ? "true" : "false", "aria-label": "navigation flyout", hidden: flyoutId !== id, children: _jsx(LayoutDesktop, { variant: "wide", children: _jsx(FlyoutSections, { topName: name, topUrl: linkUrl, sections: sections, layoutVariant: "navDesktop" }) }) }));
};
export default FlyoutDesktop;
