import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { variant as themeVariant } from "styled-system";
import "./pageHeader.scss";

import { GlobalStyles, useViewport } from "@core/theme";
import { useNavigation } from "utils/hooks";
import dataLayer from "@/helper/dataLayer";

import { Brandheader, FlyoutDesktop } from "@/components/molecules";

const NavigationWrapper = styled("div")`
  ${themeVariant({
    scale: "navigation",
    // https://github.com/styled-system/styled-system/issues/1145#issuecomment-587389487
    variants: {
      primary: {},
    },
  })}
`;

const Header = styled("header")`
  position: relative;
  ${themeVariant({
    scale: "header",
    // https://github.com/styled-system/styled-system/issues/1145#issuecomment-587389487
    variants: {
      primary: {},
    },
  })}
  @media (min-width: ${(props) => props.theme.breakpointsMap.lg.min}px) {
    z-index: 20;
  }
`;

const PageOverlayPageHeader = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  inset: 0px;
  position: absolute;
  z-index: 19;
  bottom: 0;
  height: 0;
  transition: height 500ms ease;

  &.open {
    height: 100vh;
  }
`;

const PageHeader = ({ reducedHeader }) => {
  const viewport = useViewport();
  {
    /**
     * ToDo: The flyout state should be moved to his own context
     */
  }
  const [flyoutId, setFlyoutId] = useState(null);
  const [showFlyout, setShowFlyout] = useState(false);

  const { data: { payload: navigation = [] } = {} } = useNavigation();

  const handleAnchorHover = (navLinkId, navText, hasFlyout) => {
    setFlyoutId(navLinkId);
    setShowFlyout(hasFlyout);
    if (navLinkId !== null) {
      return dataLayer({
        eventCategory: "Top Navigation",
        eventAction: "view",
        eventLabel: `${navText}`,
      });
    }
  };

  return (
    <>
      <a href="#page-main-content" className="skip-to-main-content-link">
        Zum Hauptinhalt springen
      </a>
      <Header
        variant="primary"
        onMouseLeave={() => handleAnchorHover(null, null, false)}
        id="page-header"
      >
        <GlobalStyles />
        <NavigationWrapper variant="primary">
          <Brandheader
            navigation={navigation || []}
            reducedHeader={reducedHeader}
            handleAnchorHover={handleAnchorHover}
          />
        </NavigationWrapper>
        {viewport.greaterThan.lg &&
          !reducedHeader &&
          (navigation ?? [])
            .filter((item) => item.hasFlyout === true)
            .map((navItem) => {
              const { id, name, linkUrl, sections } = navItem;
              return (
                <FlyoutDesktop
                  key={id}
                  id={id}
                  name={name}
                  linkUrl={linkUrl}
                  sections={sections}
                  flyoutId={flyoutId}
                  showFlyout={showFlyout}
                />
              );
            })}
      </Header>
      <PageOverlayPageHeader className={showFlyout ? "open" : "close"} />
    </>
  );
};

PageHeader.propTypes = {
  reducedHeader: PropTypes.bool,
};

PageHeader.defaultProps = {
  reducedHeader: false,
};

export default PageHeader;
